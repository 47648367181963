import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import MenuPage from './pages/menu/MenuPage'
import AdminPage from './pages/admin/AdminPage'
import ErrorPage from './pages/error/ErrorPage'
import {useEffect, useState } from 'react'
import firebaseApp, { db } from './firebase/credentials'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import OrderDynamicLink from './pages/order/OrderDynamicLink'
import Landing from './pages/landing/Landing'
import SuperAdmin from './pages/superAdmin/SuperAdmin'
import { doc, getDoc } from 'firebase/firestore'
import ThankyouPage from './pages/thankyou/ThankyouPage'
import Terms from './pages/tyc/Terms'
import Policy from './pages/policy/Policy'
const auth = getAuth(firebaseApp)






const App = ()=> {

  const [globalUser, setGlobalUser] = useState(null)

  const checkUserExistence = async (userId) => {
    const userDocRef = doc(db, process.env.REACT_APP_DATAUSER, userId);
    const userDoc = await getDoc(userDocRef);
    return userDoc.exists();
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userExists = await checkUserExistence(firebaseUser.uid);
        if (userExists) {
          setGlobalUser(firebaseUser);
        } else {
          await signOut(auth);
          setGlobalUser(null);
        }
      } else {
        setGlobalUser(null);
      }
    });

    return () => unsubscribe();
  }, []);




  const router = createBrowserRouter([
    {
      path: '/', element: globalUser ? <AdminPage userID={globalUser.uid} /> :   <Landing />,
      errorElement: <ErrorPage/>,
    },
    {path: '/dongato', element: <SuperAdmin />,},
    {path: '/thanks', element: <ThankyouPage />,},
    {path: '/terms', element: <Terms />,},
    {path: '/datapolicy', element: <Policy />,},
    {path: '/menu/:idMenu/:op?', element: <MenuPage />,},
    {path: '/order/:idOrder/:idRefOrder', element: <OrderDynamicLink />},
  ])

  return (
    <>
      <RouterProvider router={router}/>
    </>
  );
}

export default App
