import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';



const CategoryList = ({ list, handleDeleteCategory }) => {
  const { attributes, listeners, transform, transition, setNodeRef } = useSortable({ id: list.id });
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: 'none',
  };

  return (
    <>
      <div className="categoryList" id={list.id} ref={setNodeRef} style={style}>
        <div className="info">
          <p className="m-0 text-white text-truncate">{list.name || ' No definido'}</p>
          <span className="text-apple">{list.count} {list.count === 1 ? 'item' : 'items'}</span>
        </div>
        <div className="options">
          {
            list.count === 0 && (
              <div className="dropdown">
                <button className="dropdown-toggle btnDots" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                <ul className="dropdown-menu listDrop">
                  <button type="button" onClick={handleDeleteCategory}>Eliminar</button>
                </ul>
              </div>
            )
          }
          <button
            type="button"
            className={`btn-move-dash ms-2`}
            {...attributes}
            {...listeners}
          />
        </div>
      </div>
    </>
  );
};

export default CategoryList;
