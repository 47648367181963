import React from 'react'
import InfoGreen from '../../InfoGreen'
import {useSelector } from 'react-redux'
import updateOnlyOnePiece from '../../../../../firebase/update/updateOnlyOnePiece'


const AvalibleMenu = () => {

  const {activeMenu} = useSelector(s => s.menu)
  const {userID} = useSelector(s => s.log)
  
  const updateMenu = async(checked)=>{
    await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU, 'a', checked)
  }

  return (
    <div className="col-md-12">
      <div className="bg-b1 rounded-lg p-4 text-white my-4">
        <div className="row">
          <div className="col-md-5 align-self-center">
            <div className='d-flex align-items-center'>
              <div className="toggle-border mb-0 me-3">
                <input
                  type="checkbox"
                  role="switch"
                  id='switchAvaliableMenu'
                  checked={activeMenu}
                  onChange={e => updateMenu(e.target.checked)}
                />
                <label htmlFor="switchAvaliableMenu">
                  <div className="handle"></div>
                </label>
              </div>
              <span className='text-white'>Menú digital activo</span>
            </div>
          </div>
          <div className="col-md-7 align-self-center mt-3 mt-md-0">
            <InfoGreen
              text='¡Está muy bien tomarse unas vacaciones!. Deshabilitar tu menú digital evitará órdenes inesperadas en tu tiempo de descanso.'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AvalibleMenu
