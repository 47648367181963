import { db } from "../credentials";
import { doc, getDoc, setDoc, updateDoc, deleteField } from "firebase/firestore";

const setOrderInKitchen = async (country, number, idOrder, method) => {
  try {
    const docRef = doc(db, process.env.REACT_APP_CHECKINGORDER, `${country}${number}`);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return false;
    }

    const data = docSnap.data();

    if (!data[idOrder]) {
      return false;
    }

    
    let orderData = { ...data[idOrder] };
    if (!orderData.payment) {
      orderData.payment = {}; 
    }
    orderData.payment.method = method;

    
    const orderRef = doc(db, process.env.REACT_APP_ORDERLINKS, `${country}${number}`);
    await setDoc(orderRef, { [idOrder]: orderData }, { merge: true });

    
    const updatedData = { ...data };
    delete updatedData[idOrder];

    await updateDoc(docRef, {
      [idOrder]: deleteField()
    });

    return true;
  } catch (error) {
    console.error("Error en la operación:", error);
    return false;
  }
};

export default setOrderInKitchen;
