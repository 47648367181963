import React, { useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { useFormContext } from 'react-hook-form';
import { setCategoryProductView } from '../../../../../redux/reducer/product';

const CategoryProduct = forwardRef(({ list, errors }) => {
  const { category } = useSelector(s => s.product);
  const {setValue, trigger, register } = useFormContext();
  const dispatch = useDispatch()
  

  useEffect(() => {
    const $select = $('#categorySelect');
    $select.select2();

    $select.on('change', function() {
      const selectedValue = $(this).val();
      dispatch(setCategoryProductView(selectedValue))
      setValue('category', selectedValue, { shouldValidate: true });
      trigger('category'); 
    });

    return () => {
      $select.select2('destroy');
    };
  }, [list, setValue, trigger, dispatch]);

  useEffect(() => {
    if (category) {
      $('#categorySelect').val(category).trigger('change');
      setValue('category', category, { shouldValidate: false });
    }
  }, [category, setValue]);


  return (
    <div className="bg-b1 p-3 rounded-lg">
      <div className="d-flex align-items-center justify-content-between">
        <p className="text-bold text-apple m-0">Categoría del producto</p>
        <span className='requiredLabel'>Requerido</span>
      </div>
      <div className="mt-2 contentSelect2">
        <select
          id="categorySelect"
          className='select2-custom-dropdown'
          defaultValue={category}
          {...register('category', {
            required: { value: true, message: '¿A qué categoría pertenece tu producto?' }
          })}
        >
          <option value="">Seleccionar</option>
          {list.map(el => (
            <option key={el.id} value={el.name}>{el.name}</option>
          ))}
        </select>
      </div>
      {errors.category && <small className='errorDash mt-2'>{errors.category.message}</small>}
    </div>
  );
});

export default CategoryProduct;
