import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setShowOrderBold } from '../../../../redux/reducer/user'

const OrderLinkBold = () => {

  const dispatch = useDispatch()
  const {showOrderBold, admin, menu} = useSelector(s => s.user)

  const handleClose = ()=>{
    dispatch(setShowOrderBold(null))
    window.location.href = `https://fooderapp.co/menu/${menu?.s}${admin?.f}`
  }

  return (
    <>
      {
        showOrderBold && (
          <div className='orderPending'>
            <div className="text-center">
              <h5 className='mb-3'>Tienes una orden pendiente</h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-9 col-10 text-center">
                <p>Generamos este aviso para que no pierdas el enlace de siguimiento de tu pedido, recuerda que este enlace también lo puedes encontrar en el mensaje de WhatsApp que enviaste al confirmar el pedido.</p>
                <p><strong>Si quieres realizar otro pedido puedes cerrar este aviso</strong></p>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-3 col-6">
                  <Link
                    to={`/order/${menu?.s}${admin?.f}/${showOrderBold}`}
                    className='addModal w-100'
                    target='_blank'
                  >Ver tu orden</Link>
                </div>
                <div className="col-md-3 col-6">
                  <button
                    className='lineBtnModal w-100'
                    onClick={handleClose}
                  >Cerrar aviso</button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default OrderLinkBold