import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)




const PersonalReport = ({orderReports}) => {


  const [one, setOne] = useState(0)
  const [two, setTwo] = useState(0)

 
  useEffect(()=>{

    if(orderReports){


      const dateOne = Object.values(orderReports).filter(el => el.client.state === 4)
      setOne(dateOne.length)


      const dateTwo = Object.values(orderReports).filter(el => el.client.state === 5)
      setTwo(dateTwo.length)

      
    }

  },[orderReports])



  const data = {
    labels: ['Entregados con éxito', 'Rechazados por el administrador'],
    datasets: [
      {
        label: 'Conteo histórico',
        data: [one, two],
        backgroundColor: [
          'rgb(53, 162, 235)',
          '#f2864c',
        ],
        borderColor: [
          'rgb(53, 162, 235)',
          '#f2864c',
        ],
        borderWidth: 1,
      },
    ],
  };
  
  const options = {
    plugins: {
      legend: {
        labels: {
          color: 'white', 
        },
      },
    },
  };

  


  return (
    <>
    <div className="bg-b1 p-3 rounded-lg mb-3">
      <strong className="text-apple d-block mb-3 text-center text-md-start">Conteo estado histórico de pedidos</strong>
        <Pie data={data} options={options} />
      </div>
    </>
  )
}

export default PersonalReport