import React from 'react';
import * as XLSX from 'xlsx';

const DownloadReport = ({ orderReports }) => {

  
  const handleDownload = () => {
    
    const flatData = Object.values(orderReports).map((order) => {
      const { client, order: orderItems, payment, ...rest } = order;
      return orderItems.map((item) => {
        
        const { img, state, country, customerService, ...filteredClient } = client;
        const { img: itemImg, count, id, ...filteredItem } = item;

        
        const orderString = orderItems.map(({ title, price, comments, oneOption, multipleTitle }) => {
          return `Title: ${title}, Price: ${price}, Comments: ${comments}, Option: ${oneOption}, Multiple: ${multipleTitle}`;
        }).join(' | ');

        return {
          ...filteredClient,
          ...payment,
          ...rest,
          orderString,
          ...filteredItem,
        };
      });
    }).flat();

    
    const parseDate = (dateStr) => {
      const [day, month, year, time, period] = dateStr.split(/[/ :]/);
      const hour = period === 'PM' && parseInt(time.split(':')[0], 10) !== 12 
                   ? parseInt(time.split(':')[0], 10) + 12 
                   : time.split(':')[0];
      const minute = time.split(':')[1];
      const monthMap = {
        Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
        Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
      };
      return new Date(`${year}-${monthMap[month]}-${day}T${hour}:${minute}:00`);
    };

    
    flatData.sort((a, b) => parseDate(b.date) - parseDate(a.date));

    
    const reorderedData = flatData.map(item => {
      const {
        date, name, phone, address, neighbor, orderString, oneOption,
        multipleTitle, category, title, method, total, ...rest
      } = item;
      return {
        date, name, phone, address, neighbor, orderString, oneOption,
        multipleTitle, category, title, method, total, ...rest
      };
    });

    
    const worksheet = XLSX.utils.json_to_sheet(reorderedData);
    
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');

    
    XLSX.writeFile(workbook, 'order_report.xlsx');
  };

  return (
    <div className="row mt-2 justify-content-center">
      <div className="col-md-7 py-4">
        <button className='btn-line-apple excel' onClick={handleDownload}>
          Descargar todos los pedidos
        </button>
      </div>
    </div>
  );
}

export default DownloadReport;
