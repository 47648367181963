import { auth, db } from "../../../../firebase/credentials"
import { doc, deleteDoc } from "firebase/firestore"
import { getStorage, ref, listAll, deleteObject } from "firebase/storage"
import { deleteUser } from "firebase/auth"


const deleteUserFooder = async (id, country, tel) => {
  const storage = getStorage()

  const deleteOperations = [
    { collection: process.env.REACT_APP_DATAUSER, id: id },
    { collection: process.env.REACT_APP_AGENDA, id: id },
    { collection: process.env.REACT_APP_REPORTPAY, id: id },
    { collection: process.env.REACT_APP_RPUSER, id: id },
    { collection: process.env.REACT_APP_DATAMENU, id: id },
    { collection: process.env.REACT_APP_GLOBALINFO, id: id },
    { collection: process.env.REACT_APP_ORDERLINKS, id: `${country}${tel}` },
    { collection: process.env.REACT_APP_SUDOINSPECT, id: `${tel}` },
    { collection: process.env.REACT_APP_CHECKINGORDER, id: `${country}${tel}` },
    { collection: process.env.REACT_APP_SCPY, id: id },
  ]

  const deletePromises = deleteOperations.map(async (operation) => {
    try {
      await deleteDoc(doc(db, operation.collection, operation.id))
      return { collection: operation.collection, id: operation.id, status: 'deleted' }
    } catch (error) {
      if (error.code === 'not-found') {
        return { collection: operation.collection, id: operation.id, status: 'not found' }
      } else {
        return { collection: operation.collection, id: operation.id, status: 'error', error: error.message }
      }
    }
  })

  
  const deleteFolderContents = async (folderPath) => {
    const folderRef = ref(storage, folderPath)
    const list = await listAll(folderRef)
    const deletePromises = list.items.map((itemRef) => deleteObject(itemRef))
    await Promise.all(deletePromises)
    return { folderPath, status: 'deleted' }
  }

  
  const folderDeletePromises = [
    deleteFolderContents(`product/${id}`)
  ]

  
  const deleteAuthUser = async () => {
    
    deleteUser(auth,id).then(() => {
      return { id: id, status: 'deleted from authentication' }
    }).catch((error) => {
      return { id: id, status: 'error deleting from authentication', error: error.message }
    });
  }

  
  const authUserDeletePromise = deleteAuthUser()

  
  const results = await Promise.all([...deletePromises, ...folderDeletePromises, authUserDeletePromise])

  return results
}

export default deleteUserFooder
