import { useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"


const FormClient = ({getBack, errors, registerData, isDelivery, admin, menu})=>{

 
  const recaptchaRef = useRef(null)
  const [isValid, setIsValid] = useState(false)

  const onChange = ()=>{
    const recaptchaValue = recaptchaRef.current.getValue()
    if(recaptchaValue){
      setIsValid(true)
    }
  }

  return(
    <>
      <div className="row justify-content-center">
        <div className="col-md-11">
          <div className="row justify-content-center">   
            <div className="col-md-12 col-11">
              <div className="headCheckout">
                <button
                  className="getBack arrowBackMenu"
                  type='button'
                  onClick={getBack}
                >
                  <svg data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 61">
                    <polygon fill={`${menu?.g?.[1]}`} points="11.1 30.5 37 4.6 33.45 1.05 4 30.5 33.45 59.95 37 56.4 11.1 30.5"/>
                  </svg>
                  <span>Volver a Forma de entrega</span></button>
                <h4 className='text-center mb-0'>{isDelivery ? 'Datos de envío' : 'Recoger en punto'}</h4>
              </div>
            </div> 
            <div className="col-md-6 col-11 mb-3 ">
              <div className="input">
                <input
                  autoComplete="off"
                  type="text"
                  placeholder='Nombre Completo *'
                  {...registerData('userName',{
                    required: {value: true, message: 'Nombre requerido'},
                    maxLength: {value: 30, message: 'Nombre demasiado largo'},
                    minLength: {value: 3, message: 'Nombre demasiado corto'},
                  })}
                />
              </div>
              {errors.userName && <small className='errorMenu'>{errors.userName.message}</small>}
            </div>
            <div className="col-md-6 col-11 mb-3">
              <div className="input">
                <input
                  type="tel"
                  autoComplete="off"
                  placeholder='Celular *'
                  {...registerData('userTel',{
                    required: {value: true, message: 'Necesitamos tu número de celular'},
                    pattern: {value: /^\d{10}$/, message: 'Ingresa un número válido'},
                  })}
                />
              </div>
              {errors.userTel && <small className='errorMenu'>{errors.userTel.message}</small>}
            </div>
            {
              isDelivery ? (
                <>
                <div className="col-md-12 col-11 mb-3">
                  <div className="input">
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder='Dirección *'
                      {...registerData('address',{
                        required: {value: true, message: 'Necesitamos tu dirección'},
                        maxLength: {value: 50, message: 'Comprueba tu dirección'},
                        minLength: {value: 4, message: 'Comprueba tu dirección'},
                      })}
                    />
                  </div>
                  {errors.address && <small className='errorMenu'>{errors.address.message}</small>}
                </div>
                <div className="col-md-12 col-11 mb-3">
                  <div className="input">
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder='Barrio *'
                      {...registerData('neighbor',{
                        required: {value: true, message: 'Necesitamos una mejor orientación :)'},
                        maxLength: {value: 30, message: 'Nombre del barrio demasiado largo'},
                        minLength: {value: 3, message: 'Verifica el nombre de tu barrio'},
                      })}
                    />
                  </div>
                  {errors.neighbor && <small className='errorMenu'>{errors.neighbor.message}</small>}
                </div>
                </>
              ): null
            }
            <div className="col-12 text-center mb-3 mt-4 mt-md-0">
              <div className="d-flex justify-content-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RCMENU}
                  onChange={onChange}
                />
              </div>
            </div>
            {
              !isDelivery && (
                <div className='col-md-12 col-11'>
                  {
                    (admin?.l?.[0]?.length > 0 && admin?.l?.[1]?.length > 0 &&  admin?.l?.[2]?.length > 0 &&  admin?.l?.[3]?.length > 0 && admin?.l?.[4]?.length > 0) ? (
                      <>
                        <p className='mb-3 text-center'>Recuerda nuestra dirección: {admin?.l?.[0]} {admin?.l?.[1]} # {admin?.l?.[2]} - {admin?.l?.[3]} | {admin?.l?.[4]} {`${admin?.l?.[5] ? `, ${admin?.l?.[5]}` : ''}`}{`${admin?.l?.[6] ? `, ${admin?.l?.[6]}` : ''}`}</p>
                      </>
                    ):
                    (
                      <>
                        <p className="mb-3 text-center">El administrador no ha definido la ubicación de su local.</p>
                      </>
                    )
                  }
                </div>
              )
            }
            {
              isValid && (
                <div className="col-md-4 col-11 mt-4">
                  <button
                    className='btn-confirm '
                    type='submit'
                  >Siguiente</button>
                </div>
              )
            }
            
          </div>
        </div>
      </div>
    </>
  )
}

export default FormClient