import React from 'react';
import defalutImage from '../../../../img/vector/defaultImage.svg'
import { useDispatch, useSelector } from 'react-redux';
import { convertAmount } from '../../../tools/format';
import totalCalculate from '../../../tools/totalCalculate';
import { setOrderReports } from '../../../../redux/reducer/interactions';
import { setInfoToPrint } from '../../../../redux/reducer/ordering';


const ModalWatchOrder = () => {

  const {orderReports} = useSelector(s => s.ux)
  const {indicative} = useSelector(s => s.log)
  const dispatch = useDispatch()

  const handleReset = ()=>{
    dispatch(setOrderReports(null))
  }

  const handlePrint = (el) =>{
      dispatch(setInfoToPrint(el))
    }

  return (
    <>
    <div className="modal modal-lg fade modalDashboard" id="modalWatchOrder" tabIndex="-1" aria-labelledby="modalProduct" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-3 p-md-4">
              <button
                className="btnClose"
                data-bs-dismiss="modal"
                type='button'
                onClick={handleReset}
              >Cerrar</button>
              {
                orderReports && (
                  <>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <h5 className='text-apple mt-4 mt-md-0'>Cliente: {orderReports.client.name}</h5>
                      <p className='m-0'>Celular: {orderReports.client.phone}</p>
                      <p>Origen: <strong className='labelOp'>{orderReports.origin}</strong></p>
                      <div className=" py-2  my-2actionSection">
                        <a rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=${indicative}${orderReports.client.phone}`} className="btnSmall wspIcon" target="_blank">Chatear</a>
                        <a rel="noopener noreferrer" href={`tel:+${indicative}${orderReports.client.phone}`} className="btnSmall callClient ms-1" target="_blank">Llamar</a>
                      </div>
                      <hr  className='d-block d-md-none'/>
                    </div>
                    <div className="col-md-6">
                      <table className="text-12">
                        <tbody>
                          <tr>
                            <td>No. Pedido</td>
                            <td className="text-uppercase">{orderReports.op}</td>
                          </tr>
                          <tr>
                            <td>Ingreso</td>
                            <td>{orderReports.client?.date}</td>
                          </tr>
                          <tr>
                            <td>Medio de pago</td>
                            <td style={{textTransform: 'capitalize'}}>{orderReports.payment?.method === 'cash' ? 'Efectivo': orderReports.payment?.method}</td>
                          </tr>
                          <tr>
                            <td>Forma de entrega</td>
                            {
                              orderReports.payment?.delivery ? (
                                <td>Domicilio</td>
                              ):
                              (
                                <td>Recoger en punto</td>
                              )
                            }  
                          </tr>
                          {
                            orderReports.payment?.delivery && (
                              <tr>
                                <td>Dirección</td>
                                <td>{orderReports.client?.address}, {orderReports.client?.neighbor} </td>
                              </tr>
                            )
                          }
                          {/* <tr>
                            <td>Descuento</td>
                            <td>{convertAmount(orderReports.payment?.discount)}</td>
                          </tr> */}
                          {
                            orderReports?.payment?.delivery && (

                              <tr>
                                <td>Valor domicilio </td>
                                <td>{convertAmount(orderReports?.payment?.deliveryPrice)}</td>
                              </tr>
                            )
                          }
                          <tr>
                            <td>Valor total {orderReports.payment?.delivery ? '+ Domicilio': 'del pedido'}</td>
                            <td className='text-apple'>{convertAmount(totalCalculate(orderReports))}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  
                    <hr className="mb-4"/>
                    {
                      orderReports.order && (
                        <div className='row'>
                        {
                          orderReports.order.map((item, index)=> (
                            <div className="col-md-6 mb-3" key={index}>
                              <div className="cardOrderResume text-12 h-100" >
                                <div className="row">
                                  <div className="col-4">
                                    <img
                                      src={item?.img || defalutImage}
                                      alt={item?.title || 'imagen'}
                                      className='img-fluid rounded-2'
                                      onError={(e) => e.target.src = defalutImage}
                                    />
                                  </div>
                                  <div className="col-8 ">
                                    <p className="text-truncate text-apple m-0 text-12">{item?.title}</p>
                                    <p className="text-truncate text-apple m-0 text-12">Categoría: {item?.category}</p>
                                    <p className="text-truncate text-apple m-0 text-12">Cantidad: {item?.count}</p>
                                    <p className="text-truncate text-apple m-0 text-12">Total parcial: {convertAmount(item?.price * item?.count)}</p>
                                  </div>
                                  <div className="col-12 mt-2">
                                    <span>Opción única: {item?.oneOption?.length === 0 ? '--- No hay ---': item?.oneOption}</span>
                                  </div>
                                  <div className="col-12">
                                    <span>Opción múltiple: {item?.multipleTitle?.length === 0 ? '--- No hay ---': item?.multipleTitle}</span>
                                  </div>
                                  <div className="col-12">
                                    <span>Comentarios: {item?.comments?.length === 0 ? '--- No hay ---': item?.comments}</span>
                                  </div>
        
                                </div>
                                <hr />
                              </div>
                            </div>
                          ))
                        }
                        </div>
                      )
                    }

                  </>    
                )
              }

              <div className="row justify-content-center">
                <div className="col-md-5 my-3 ">
                  <button
                    className="btnPrintTicket"
                    data-bs-toggle="modal"
                    data-bs-target="#modalPrintOrder"
                    onClick={()=> handlePrint(orderReports)}
                  >Imprimir ticket</button>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalWatchOrder
