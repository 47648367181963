import React from 'react'
import { useSelector } from 'react-redux'

const ColorsTemplate = ({color1,color2,color3,color4}) => {

  const {colors} = useSelector(s => s.menu)
  
  return (
    <>
      <div className="bg-b1 p-3 rounded-lg">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0">Selecciona tu paleta de colores</p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        <div className="row text-center mt-3">
          <div className="col-md-12 align-self-center">
            <div className="row">
              <div className="col-md-3 col-6 mb-3 mb-md-0">
                <input
                  type="color"
                  {...color1}
                />
              </div>
              <div className="col-md-3 col-6 mb-3 mb-md-0">
                <input
                  type="color"
                  {...color2}
                />
              </div>
              <div className="col-md-3 col-6 mb-3 mb-md-0">
                <input
                  type="color"
                  {...color3}
                />
              </div>
              <div className="col-md-3 col-6 mb-3 mb-md-0">
                <input
                  type="color"
                  {...color4}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ColorsTemplate