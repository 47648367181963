import { db } from "../../../../../firebase/credentials"
import { doc, updateDoc } from "firebase/firestore"



const updateScheduleModule = async(userID, data)=>{

  const {week,deliveryPrice,minDelivery} = data


  const ref = doc(db, process.env.REACT_APP_DATAMENU, userID)
  try {
    await updateDoc(ref, {
      b: week,
      r: deliveryPrice,
      u: minDelivery
    })
    return true
  } catch (error) {
    return false
  }

}

export default updateScheduleModule