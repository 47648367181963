import { db } from "../credentials"
import {onSnapshot, doc} from 'firebase/firestore'



const getOrderInformation = async(idOrder, oderLink, callback) => {
  
  onSnapshot(doc(db, process.env.REACT_APP_ORDERLINKS, idOrder), (doc) => {
    try {
      return callback(doc.data()[oderLink])
    } catch (error) {
      return callback(null)
    }
  })
}

export default getOrderInformation