import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { convertAmount } from "../../../tools/format"
import InfoGreen from "../InfoGreen"
import { setDataDeleteOrder, setIsError, setStateLoading, setStateNotification, setTextNotificate } from "../../../../redux/reducer/interactions";
import emptyicon from '../../../../img/dashboard/without_order.svg'
import setStateOrder from "../../../../firebase/update/stateKitchen"
import totalCalculate from "../../../tools/totalCalculate";
import defaultImage from '../../../../img/vector/defaultImage.svg'

const CommingOrder = () => {

  const {filteredZero, phone: IDMENU, indicative} = useSelector(s => s.log)
  const dispatch = useDispatch()


  const handleCancelOrder = (order,phone)=>{
    dispatch(setDataDeleteOrder({order,phone}))
  }
  

  const handleAccept = async(op) =>{
    dispatch(setStateLoading(true))
    const response = await setStateOrder(indicative, IDMENU,op,1)
    if(!response){
      dispatch(setTextNotificate('Ha ocurrido un error, inténtalo mas tarde'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true)) 
    }
    dispatch(setStateLoading(false))
    dispatch(setDataDeleteOrder(null))
  }



  
  
  return (
    <>

      <div className="rounded-lg px-0 px-md-3 pt-3 pb-0 pb-md-3">
        <p className="text-apple">Pedidos en cola <strong>{filteredZero.length}</strong></p>
        {
          
          filteredZero.length > 0 ? 
          (<>
            <div className="accordion accKitchen" id="incomingProduct">
              {
                Object.values(filteredZero).map((el, index) => (
                  <div className="accordion-item" key={el?.op}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${el?.op}`}
                      aria-expanded="false"
                      aria-controls={`collapse${el?.op}`}
                    >
                      <p className="m-0 text-truncate" style={{textTransform: 'capitalize'}}>{el?.client?.name}</p>
                      <span>{el?.origin}</span>
                    </button>
                    <div
                      id={`collapse${el?.op}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#incomingProduct"
                    >
                      <div className="accordion-body p-2">
                        <table className="text-12">
                          <tbody>
                            <tr>
                              <td>No. Pedido</td>
                              <td className="text-uppercase">{el?.op}</td>
                            </tr>
                            <tr>
                              <td>Ingreso</td>
                              <td>{el?.client?.date}</td>
                            </tr>
                            <tr>
                              <td>Medio de pago</td>
                              <td style={{textTransform: 'capitalize'}}>{el?.payment?.method === 'cash' ? 'Efectivo': el?.payment?.method}</td>
                            </tr>
                            <tr>
                              <td>Forma de entrega</td>
                              {
                                el?.payment?.delivery ? (
                                  <td>Domicilio</td>
                                ):
                                (
                                  <td>Recoger en punto</td>
                                )
                              }  
                            </tr>
                            {
                              el?.payment?.delivery && (
                                <tr>
                                  <td>Dirección</td>
                                  <td>{el?.client?.address}, {el?.client?.neighbor} </td>
                                </tr>
                              )
                            }
                            <tr>
                              <td>Celular </td>
                              <td>{el?.client?.phone} </td>
                            </tr>
                            {/* <tr>
                              <td>Descuento</td>
                              <td>{convertAmount(el?.payment?.discount)}</td>
                            </tr> */}
                            {
                              el?.payment?.delivery && (

                                <tr>
                                  <td>Valor domicilio </td>
                                  <td>{convertAmount(el?.payment?.deliveryPrice)}</td>
                                </tr>
                              )
                            }
                            <tr>
                              <td>Valor total {el?.payment?.delivery ? '+ Domicilio': 'del pedido'}</td>
                              <td>{convertAmount(totalCalculate(el))}</td>
                            </tr>
                            <tr>
                              <td>Acciones</td>
                              <td>
                                <div className=" pt-2 justify-content-end w-100">
                                  <a rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=${indicative}${el?.client?.phone}`} className="btnSmall wspIcon" target="_blank">Chatear</a>
                                  <a rel="noopener noreferrer" href={`tel:+${indicative}${el?.client?.phone}`} className="btnSmall callClient ms-1" target="_blank">Llamar</a>
                                  <a rel="noopener noreferrer" href={`https://fooderapp.co/order/${indicative}${IDMENU}/${el?.op}`} className="btnSmall linkOrder" target="_blank">Seguimiento</a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <hr className="mb-4"/>
                        {
                          el?.order && (
                            <>
                            {
                              el?.order.map((item, index)=> (
                                <div className="cardOrderResume text-12" key={index}>
                                  <div className="row">
                                    <div className="col-4">
                                      <img
                                        src={item?.img || defaultImage}
                                        alt={item?.title || 'imagen'}
                                        className='img-fluid rounded-2'
                                        onError={(e) => e.target.src = defaultImage}
                                      />
                                    </div>
                                    <div className="col-8 ">
                                      <p className="text-truncate text-apple m-0 text-12">{item?.title}</p>
                                      <p className="text-truncate text-apple m-0 text-12">Categoría: {item?.category}</p>
                                      <p className="text-truncate text-apple m-0 text-12">Cantidad: {item?.count}</p>
                                      <p className="text-truncate text-apple m-0 text-12">Total parcial: {convertAmount(item?.price * item?.count)}</p>
                                    </div>
                                    <div className="col-12 mt-2">
                                      <span>Opción única: {item?.oneOption?.length === 0 ? '--- No hay ---': item?.oneOption}</span>
                                    </div>
                                    <div className="col-12">
                                      <span>Opción múltiple: {item?.multipleTitle?.length === 0 ? '--- No hay ---': item?.multipleTitle}</span>
                                    </div>
                                    <div className="col-12">
                                      <span>Comentarios: {item?.comments?.length === 0 ? '--- No hay ---': item?.comments}</span>
                                    </div>

                                  </div>
                                  <hr />
                                </div>
                              ))
                            }
                            </>
                          )
                        }
                        
                        <div className="row">
                          <div className="col-6">
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#confirmDeleteOrder"
                              onClick={()=> handleCancelOrder(el?.op, el?.client?.phone)}
                              className="w-100 m-0 btnDelete"
                            >Rechazar</button>
                          </div>
                          <div className="col-6">
                            <button
                              className="btnAccept"
                              onClick={()=> handleAccept(el?.op)}
                            >Aceptar</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </>)
          :
          (
            <>
              <div className="bg-b1 p-3 text-center rounded-4">
                <div className="iconState">
                  <img src={emptyicon} alt="icon"/>
                </div>
                <p className="m-0 text-white">No hay pedidos por aprobar</p>
              </div>
              <InfoGreen
                bg={true}
                text='Si tienes ventas bajas, no olvides visitar las secciones de CREAR POST y CREAR ESTADO. Estas herramientas te ayudarán a publicar y promocionar tus platos en tus redes sociales.'
              />

            </>
          )
        }
      </div>
    </>
  );
};

export default CommingOrder