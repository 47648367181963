import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import InfoGreen from '../../InfoGreen';
import { callEncriptBoldUser } from '../../../../../firebase/credentials';
import { useDispatch, useSelector } from 'react-redux';
import { setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../../redux/reducer/interactions';
import updateOnlyOnePiece from '../../../../../firebase/update/updateOnlyOnePiece';

const KeysForm = ({ image, title, switchText, description, production, testing }) => {

  const dispatch = useDispatch()

  const {userID, bold} = useSelector(s => s.log)
  const { register, handleSubmit, formState: { errors, isDirty } } = useForm();
  const [hide1, setHidde1] = useState(true);
  const [hide2, setHidde2] = useState(true);
  const [hide3, setHidde3] = useState(true);
  const [hide4, setHidde4] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  

  const handleMode = async(event) => {
    let newValue = {
      a: bold.a, b: event.target.checked, c: bold.c, d: bold.d
    }
    await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAUSER, 'w', newValue)
  }
  const handleActive = async(event) => {
    let newValue = {
      a: event.target.checked, b: bold.b, c: bold.c, d: bold.d
    }
    await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAUSER, 'w', newValue)
  }

  const onSubmit = async (data) => {
    console.log(data)
    if(isDirty){
      dispatch(setStateLoading(true))
      
      const success = await callEncriptBoldUser({ data, userID });

      let newValue = {
        a: bold.a, b: bold.b, c: data.testingKey1, d: data.productionKey1
      }
      await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAUSER, 'w', newValue)
      
      if (success) {
        dispatch(setTextNotificate('Llaves Bold guardada con éxito!'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false))
        setIsSuccess(true)
      } else {
        dispatch(setTextNotificate('Hubo un error al guardar las llaves, inténtalo nuevamente'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true))
        setIsSuccess(false)
      }
      dispatch(setStateLoading(false))
    }else{
      console.log('no se editó')
    }
  }
  

 

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className={`col-12 ${bold.a ? 'mb-3': ''}`}>
          <div className="row justify-content-center">
            <div className="col-md-10 align-self-center">
              <div className='d-flex align-self-center mb-4 mb-md-0'>
                <div className="form-check form-switch justify-content-center">
                  <input
                    className="form-check-input me-3"
                    type="checkbox"
                    role="switch"
                    {...register("activeBold")} 
                    checked={bold.a} 
                    onChange={handleActive}
                  />
                </div>
                <span className='text-white d-flex align-items-center'>Pagos Bold {bold.a ? 'Activado': 'Desactivado'} </span>
              </div>
            </div>
            <div className="col-md-2 col-6 align-items-center">
              <img src={image} alt="" className='img-fluid'/>
            </div>
          </div>
        </div>
      </div>
      {
        bold.a && (
          <div className="row">
          <div className="col-12">
              <hr className='mt-0'/>
            </div>

            <div className="col-md-12 align-self-center">
              <h5 className='text-apple mb-3 text-center'>{title}</h5>
              <p>{description}</p>
            </div>
            <div className="col-md-12">
              <h6 className='text-apple mb-4'>{production.title}</h6>
              <div className="bg-white p-3 rounded-3 text-dark">
                <div>
                  <h6 className='mb-2'>{production.label1}</h6>
                  <p>{production.description1}</p>
                  <div className="inputPass">
                    <input 
                      autoComplete="off"
                      defaultValue={bold.c}
                      type={hide1 ? 'password' : 'text'}
                      {...register("productionKey1", {
                        required: "Este campo es obligatorio",
                        minLength: {
                          value: 8,
                          message: "Debe tener al menos 8 caracteres",
                        },
                        maxLength: {
                          value: 100,
                          message: "No debe exceder los 100 caracteres",
                        },
                      })}
                    />
                    <button type='button' className={hide1 ? 'hide' : ''} onClick={() => setHidde1(!hide1)}/>
                  </div>
                  {errors.productionKey1 && (
                    <small className="errorDash transform-y">{errors.productionKey1.message}</small>
                  )}
                </div>
                <div>
                  <h6 className='mb-2'>{production.label2}</h6>
                  <p>{production.description2}</p>
                  <div className="inputPass">
                    <input 
                      autoComplete="off"
                      placeholder={bold?.c?.length > 0 ? '****llave secreta de producción encriptadas****': ''}
                      type={hide2 ? 'password' : 'text'}
                      {...register("productionKey2", {
                        required: "Este campo es obligatorio",
                        minLength: {
                          value: 8,
                          message: "Debe tener al menos 8 caracteres",
                        },
                        maxLength: {
                          value: 100,
                          message: "No debe exceder los 100 caracteres",
                        },
                      })}
                    />
                    <button type='button' className={hide2 ? 'hide' : ''} onClick={() => setHidde2(!hide2)}/>
                  </div>
                  {errors.productionKey2 && (
                    <small className="errorDash transform-y">{errors.productionKey2.message}</small>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <h6 className='text-apple my-4'>{testing.title}</h6>
              <div className="bg-white p-3 rounded-3 text-dark">
                <div>
                  <h6 className='mb-2'>{testing.label1}</h6>
                  <p>{testing.description1}</p>
                  <div className="inputPass">
                    <input 
                      autoComplete="off"
                      defaultValue={bold.d}
                      type={hide3 ? 'password' : 'text'}
                      {...register("testingKey1", {
                        required: "Este campo es obligatorio",
                        minLength: {
                          value: 8,
                          message: "Debe tener al menos 8 caracteres",
                        },
                        maxLength: {
                          value: 100,
                          message: "No debe exceder los 100 caracteres",
                        },
                      })}
                    />
                    <button type='button' className={hide3 ? 'hide' : ''} onClick={() => setHidde3(!hide3)}/>
                  </div>
                  {errors.testingKey1 && (
                    <small className="errorDash transform-y">{errors.testingKey1.message}</small>
                  )}
                </div>
                <div>
                  <h6 className='mb-2'>{testing.label2}</h6>
                  <p>{testing.description2}</p>
                  <div className="inputPass">
                    <input 
                      autoComplete="off"
                      placeholder={bold?.d?.length > 0 ? '****llave secreta de pruebas encriptadas****': ''}
                      type={hide4 ? 'password' : 'text'}
                      {...register("testingKey2", {
                        required: "Este campo es obligatorio",
                        minLength: {
                          value: 8,
                          message: "Debe tener al menos 8 caracteres",
                        },
                        maxLength: {
                          value: 100,
                          message: "No debe exceder los 100 caracteres",
                        },
                      })}
                    />
                    <button type='button' className={hide4 ? 'hide' : ''} onClick={() => setHidde4(!hide4)}/>
                  </div>
                  {errors.testingKey2 && (
                    <small className="errorDash transform-y">{errors.testingKey2.message}</small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12">
              <h6 className='my-4 text-center text-apple'>Estás en modo de {bold.b ? 'Producción' : 'Pruebas'}</h6>
              <div className="d-flex justify-content-center my-4">
                <div className="toggle-border">
                <input
                    id="one"
                    type="checkbox"
                    {...register("testMode")} 
                    checked={bold.b} 
                    onChange={handleMode}
                  />
                  <label htmlFor="one">
                    <div className="handle"></div>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12">
              {bold.b ? (
                <InfoGreen
                  text='Ahora puedes simular los pagos desde tu menú digital. NO RECIBIRÁS PAGOS REALES, sin embargo, puedes verificar desde tu menú digital si las llaves que ingresaste funcionan correctamente. No olvides preguntar a tu proveedor de pagos cuáles son los datos FAKE para simular todo el proceso de pagos.'
                />
              ) : (
                <InfoGreen
                  text={`¡Genial! Ahora puedes recibir tu dinero desde tu proveedor de pagos ${switchText}. Verifica que el botón de pago en tu menú se encuentre disponible y no genere errores.`}
                />
              )}
            </div>
            {
              isSuccess && (
                <div className="alert alert-success" role="alert">
                  Tus credenciales Bold han sido encriptadas para mayor seguridad.
                </div>
              )
            }
            <div className="col-12 mt-4 mb-3">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <button
                    className={`btnSave w-100 ${isDirty ? '': 'disabled'}`}
                    type='submit'
                  >Guardar llaves</button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </form>
  );
};

export default KeysForm;