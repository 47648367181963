import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveMenu, setSearching } from '../../../../redux/reducer/user/index'
import testLogo from '../../../../img/menu/test_logo.svg'

const Header = () => {

  const { admin, searching, menu } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [logo, setLogo] = useState()

  const handleFilter = (e) => {
    dispatch(setSearching(e.target.value))
  }

  useEffect(() => {
    if (admin?.i?.length === 0) {
      setLogo(testLogo)
    } else {
      setLogo(admin?.i)
    }
  }, [admin])

  const handleToggleMenu = () => dispatch(setActiveMenu())

  
  const handleShare = async () => {
    const menuURL = `${window.location.origin}/menu/57${admin?.f}`; 

    if (navigator.share) {
      try {
        await navigator.share({
          title: '¡Mira este menú!',
          text: 'Te invito a revisar el menú de este restaurante.',
          url: menuURL
        });
        console.log('Contenido compartido exitosamente');
      } catch (error) {
        console.error('Error al compartir:', error);
      }
    } else {
      
      navigator.clipboard.writeText(menuURL).then(() => {
        
      }).catch(err => {
        console.error('Error al copiar el enlace:', err);
      });
    }
  }

  return (
    <div className='bg-c4'>
      <div className="container-fluid pb-5 px-0">
        <header className='headerMenu'>
          <div className="content">
            <div className="row">
              <div className="col-md-6 col-5">
                <div className="d-flex align-items-center">
                  <button
                    className='menuOptions'
                    type='button'
                    onClick={handleToggleMenu}
                  >
                    <div /><div /><div />
                  </button>
                  <div className="logo">
                    <img src={logo} alt={admin?.j} className='img-fluid' />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-7 align-self-center">
                <div className="d-flex align-items-center">
                  <div className="searchContent w-100">
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder='Buscar...'
                      value={searching}
                      onChange={e => handleFilter(e)}
                    />
                  </div>
                  <button className='btnShare' onClick={handleShare}>
                    <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="m322.67,379.3c-49.52-24.75-98.77-49.38-147.96-73.97-32.51,39.59-87.41,39.74-119.87,6.87-31.04-31.43-30.58-82.37.97-113.31,32.23-31.6,86.16-31.65,118.86,7.86,49.27-24.63,98.64-49.32,147.93-73.96-11.88-45.76,16.77-86.04,53.62-97.31,37.43-11.45,77.91,5.76,95.37,40.97,17.45,35.19,6.76,77.67-25.26,100.66-32.04,23.01-79.26,20.31-109.28-15.85-49.26,24.64-98.62,49.33-148.25,74.15,3.86,13.67,4.03,27.16-.07,41.19,49.38,24.7,98.72,49.39,148.41,74.25,16.52-19.78,37.47-30.65,63.36-30.73,19.37-.06,36.57,6.47,51.42,18.93,29.28,24.58,36.77,67.23,17.77,100.7-18.94,33.36-59.41,48.43-95.75,35.92-33.02-11.37-62.24-47.87-51.26-96.37Z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        <h1 className='d-none'>{admin?.j}</h1>
        <h2 className='d-none'>{menu?.t?.c}</h2>
      </div>
    </div>
  )
}

export default Header
