import { db } from "../credentials"
import {onSnapshot, doc} from 'firebase/firestore'



const getOrderInfo = async(phone, callback) => {
  
  onSnapshot(doc(db, process.env.REACT_APP_ORDERLINKS, phone), (doc) => {
    try {
      return callback(doc.data())
    } catch (error) {
      return callback(null)
    }
  })
}

export default getOrderInfo