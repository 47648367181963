import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  admin: {},
  menu: {},
  searching: '',
  productSelected: null,
  counter: 1,
  priceOne: 0,
  priceMultiple: [],
  comment: '',
  activeMenu: false,
  showAboutCompany: false,
  favoriteStorage: [],
  order: [],
  activeOrder: false,
  clientAddress: [],
  showTYC: false,
  orderSent: '',
  loadSendMenu: false,
  dataClient: {},
  userID: null,
  showOrderBold: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAdminInformation: (state,action) =>{
      state.admin = action.payload
    },
    setUserID: (state,action) =>{
      state.userID = action.payload
    },
    setMenuInformation: (state,action) =>{
      state.menu = action.payload
    },
    setSearching: (state,action) =>{
      state.searching = action.payload
    },
    setProductSelected: (state,action) =>{
      state.productSelected = action.payload
    },
    setCounter: (state,action) =>{
      state.counter = action.payload
    },
    setComment: (state,action) =>{
      state.comment = action.payload
    },
    setPriceOne: (state,action) =>{
      state.priceOne = action.payload
    },
    setResetPriceMultiple: (state) =>{
      state.priceMultiple = []
    },
    setPriceMultiple: (state,action) =>{
      let list = [...state.productSelected.j.op]
      let refmultiple = [...state.priceMultiple]
      let ref = state.priceMultiple.filter(el => el.title === list[action.payload].title)
      if(ref.length === 0){
        state.priceMultiple.push(list[action.payload])
      }else{
        let obj = refmultiple.findIndex(el => el.title === list[action.payload].title)
        state.priceMultiple.splice(obj,1)
      }
    },
    setActiveMenu: (state) =>{
      if(state.activeMenu){
        state.activeMenu = false
      }else{
        state.activeMenu = true
      }
    },
    setActiveTYC: (state) =>{
      if(state.showTYC){
        state.showTYC = false
      }else{
        state.showTYC = true
      }
    },
    setActiveOrder: (state) =>{
      if(state.activeOrder){
        state.activeOrder = false
      }else{
        state.activeOrder = true
      }
    },
    setShowAboutCompany : (state) =>{
      if(!state.showAboutCompany){
        state.showAboutCompany = true
      }
    },
    setCloseAboutCompany: (state) =>{
      state.showAboutCompany = false
    },
    setFavoriteStorage: (state, action) =>{
      state.favoriteStorage = action.payload
    },
    addToFavoriteStorage: (state, action) =>{
      let data = [...state.favoriteStorage]
      let res = data.findIndex(el => el === action.payload)
      if(res === -1){
        state.favoriteStorage.push(action.payload)
      }else{
        data.splice(res,1)
        state.favoriteStorage = data
      }
    },
    addOrder: (state, action) =>{
      state.order.push(action.payload)
    },
    customizeOrder: (state, action) =>{
      let copy = JSON.parse(JSON.stringify(state.order))
      if(action.payload.type === 'minus'){
        if(copy[action.payload.index].count > 1){
          copy[action.payload.index].count -= 1
          state.order = copy
        }else{
          copy.splice(action.payload.index, 1)
          state.order = copy
        }
      }
      if(action.payload.type === 'add'){
        copy[action.payload.index].count += 1
        state.order = copy
      }
    },
    setCleanOrder : (state) =>{
      state.order = []
    },
    setDataClient : (state, action) =>{
      state.dataClient = action.payload
    },
    addAddressClient: (state, action) =>{
      console.log(action.payload)
    },
    onloadState: (state) =>{
      state.activeOrder = false
      state.activeMenu = false
      state.showAboutCompany = false
    },
    setOrderSent: (state, action) =>{
      state.orderSent = action.payload
    },
    setLoadMenu: (state, action) =>{
      state.loadSendMenu = action.payload
    },
    setShowOrderBold: (state,action) =>{
      state.showOrderBold = action.payload
    }
  }
})

export const {
  setAdminInformation, setMenuInformation,setSearching,
  setProductSelected, setCounter,setPriceOne,setPriceMultiple,
  setComment,setResetPriceMultiple,setActiveMenu,setShowAboutCompany,
  setCloseAboutCompany,setFavoriteStorage,addToFavoriteStorage,addOrder,
  customizeOrder, setCleanOrder,setActiveOrder,addAddressClient,
  setActiveTYC, onloadState, setShowOrderBold,
  setOrderSent,setLoadMenu,
  setDataClient,setUserID
} = userSlice.actions

export default userSlice.reducer



