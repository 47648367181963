import React from 'react'
import LoadingSudo from '../LoadingSudo'
import { useDispatch, useSelector } from 'react-redux'
import { setDataRequest } from '../../../redux/reducer/sudo'
import Messages from '../Messages'

const NotificationSect = () => {

  const {listUsers} = useSelector(s => s.sudo)
  const dispatch = useDispatch()




  const selectToChange = (el, index) => {

    let selectValue = parseInt(document.getElementById(`selectRequest-${index}`).value)
    if(selectValue === null || selectValue === undefined){
      selectValue = 1
    }

    const obj = {...el}
    obj.credits = selectValue
    
    dispatch(setDataRequest(obj))
  }

  return (
    <section className="p-4">
      {
        listUsers.length === 0 ? (
          <>
            <LoadingSudo />
          </>
          )
          :
          (
            <>
              <Messages />
              <h4 className="text-center text-apple mb-4">Nuevas solicitudes</h4>    
              <div className="row justify-content-center">
                {
                  listUsers.map((el, index) => {

                    const isSolve = el?.solve

                    if(isSolve === false){
                      return (
                        <div className="col-md-10" key={el?.id}>
                          <div className="bg-b1 rounded-lg p-3 mb-3">
                            <div className="row">
                              <div className="col-md-9 align-self-center">
                                <div className="row">
                                  <div className="col-md-8">
                                    <p className='m-0'>Nombre: <strong className='text-apple'>{el?.name}</strong></p>
                                    <p className='m-0'>Correo: <strong className='text-apple'>{el?.email}</strong></p>
                                    <p className='m-0'>Celular: <strong className='text-apple'>+ {`(${el?.country})`} {el?.tel}</strong></p>
                                    <p className='m-0'>Fecha de solicitud: <strong className='text-apple'> {el?.date}</strong></p>
                                  </div>
                                  <div className="col-md-4 align-self-center">
                                    <div className="d-none">
                                      <p className='text-apple'>Créditos asignados</p>
                                      <div className="inputSudo">
                                        <select id={`selectRequest-${index}`}>
                                          <option value="0">50 Créditos</option>
                                          <option value="1">100 Créditos</option>
                                          <option value="2">300 Créditos</option>
                                          <option value="3">500 Créditos</option>
                                          <option value="4">1000 Créditos</option>
                                          <option value="5">2000 Créditos</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 align-self-center mt-4 mt-md-0">
                                <button
                                  type='button'
                                  className='btn-line-apple mb-3'
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalCreateUser"
                                  onClick={()=> selectToChange(el, index)}
                                >Aceptar</button>
                                <button
                                  type='button'
                                  className='btn-orange m-0'
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalConfirmRequest"
                                  onClick={()=> selectToChange(el, index)}
                                >Ignorar</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })
                }
              </div> 
            </>
          )
      }
    </section>
  )
}

export default NotificationSect