import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import Viewer from './create/Viewer';
import CategoryProduct from './create/CategoryProduct';
import InfoGreen from '../InfoGreen';
import InfoProduct from './create/InfoProduct';
import OneOption from './create/OneOption';
import MultipleOption from './create/MultipleOption';
import AditionalComments from './create/AditionalComments';
import Chilli from './create/Chilli';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import uniqid from 'uniqid';
import { setChilli, setComments, setIDProductSelected, setMultipleOption, setNewDescription, setNewTitle, setProductDiscount, setProductPrice, setResetProductValues, setUniqueOption } from '../../../../redux/reducer/product';
import { addProduct, undateProductImage } from '../../../../firebase/credentials';
import { setIsEdit, setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions';
import updateOnlyOnePiece from '../../../../firebase/update/updateOnlyOnePiece';
import $ from 'jquery';

const CreateProduct = forwardRef((props, ref) => {
  const { updateProductoFile, description, uniqueoption, multipleoption, title, chilli, comments, category, price, discount } = useSelector(state => state.product);
  const { userID, maxProductLength } = useSelector(state => state.log);
  const { listCategories, listProducts } = useSelector(state => state.menu);
  const { productSelectedID } = useSelector(state => state.product);
  const { isEdit } = useSelector(state => state.ux);
  
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onChange',
  });

  const forceResetSelect = () => {
    setTimeout(() => {
      $('#categorySelect').val('').trigger('change');
      setValue('category', ''); 
      clearErrors('category'); 
    }, 500);
  };
  
  

  const { handleSubmit, formState: { errors }, setValue,register, watch, trigger, reset, clearErrors } = methods;

  const onSubmit = async (data) => {

    if (isEdit) {
      dispatch(setStateLoading(true))
      let newList = listProducts.map(product => JSON.parse(JSON.stringify(product)))
      let indexRef = newList.findIndex(el => el.c === productSelectedID)

      const editProduct = {
        a: newList[indexRef].a, 
        b: newList[indexRef].b, 
        c: productSelectedID, 
        d: null, 
        e: title, 
        f: [price,parseInt(discount) || null], 
        g: category ,
        h: description, 
        i: uniqueoption,
        j: multipleoption,
        k: chilli, 
        l: comments, 
      }

      if(updateProductoFile.substring(0,6) === 'https:'){
        editProduct.d = updateProductoFile
      }else{
        try { 
          const response = await undateProductImage(userID,updateProductoFile,`${editProduct.c}`)
          if(response.success){
            editProduct.d = response.url
          }
        } catch (error) {
          console.log(error)
          dispatch(setStateLoading(false))
        }
      }
      newList[indexRef] = editProduct
      try {
        await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU,'p', newList)

        dispatch(setTextNotificate('¡Este producto ha sido modificado!'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false))
        dispatch(setStateLoading(false))
        
        dispatch(setIsEdit(false))
        document.querySelector('#catalogP-tab').click()
        dispatch(setResetProductValues())
        dispatch(setIDProductSelected(''))
      } catch (error) {
        dispatch(setTextNotificate('Ha ocurrido un error, inténtalo mas tarde'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true))
        dispatch(setStateLoading(false))
      }

      reset()
      forceResetSelect()
      
    } else {

    

      let {category,price,discountedPrice} = data
  
      const newProduct = {
        a: true, 
        b: false, 
        c: uniqid(),
        d: null, 
        e: title, 
        f: [price,parseInt(discountedPrice) || null], 
        g: category , 
        h: description, 
        i: uniqueoption,
        j: multipleoption,
        k: chilli, 
        l: comments, 
      }

      dispatch(setStateLoading(true))
  
      try { 
        const response = await undateProductImage(userID,updateProductoFile,`${newProduct.c}`)
        if(response.success){
          newProduct.d = response.url
        }
      } catch (error) {
        console.log(error)
        dispatch(setStateLoading(false))
      }
  
      
      try {
        await addProduct(userID, newProduct)
        dispatch(setTextNotificate('¡Nuevo producto agregado!. Visualiza todos tus productos en la pestaña "Catálogo"'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false))
        dispatch(setStateLoading(false))
        dispatch(setResetProductValues())
        reset()
        forceResetSelect()
        
      } catch (error) {
        dispatch(setTextNotificate('Hubo un error al subir este producto, inténtelo nuevamente.'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true))
        dispatch(setStateLoading(false))
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (isEdit) {
      setValue('registerNameProduct', title);
      setValue('price', price);
      setValue('discountedPrice', discount);
      setValue('registerDescription', description);
      setValue('category', category); 
    }
  }, [isEdit, title, price, discount, description, category, setValue]);

  useImperativeHandle(ref, () => ({
    resetSelect: () => {
      console.log("Ejecutando resetSelect en CategoryProduct ✅");
      $('#categorySelect').val('').trigger('change');
      setValue('category', '', { shouldValidate: false });
      reset()
      forceResetSelect()
    }
  }));



  return (
    <FormProvider {...methods}>
      {(listProducts.length < maxProductLength || isEdit) ? (
        <>
          <form className="py-2 py-md-4 mb-5 mb-md-0" onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-5 mb-md-0">
              <div className="col-md-8">
                {isEdit ? (
                  <h4 className='mb-4 text-apple'>Editando producto</h4>
                ) : (
                  <div className="d-flex mb-4 align-items-center justify-content-between">
                    <h4 className=' text-center text-apple m-0'>Crear producto</h4>
                    <span className='text-apple d-inline-flex ms-4'>({listProducts.length} de {maxProductLength})</span>
                  </div>
                )}
                <div className="row">
                  {listCategories.length === 0 ? (
                    <div className="col-12 mb-2">
                      <InfoGreen
                        bg={true}
                        text='Para agregar productos debes crear al menos una categoría'
                      />
                    </div>
                  ) : null}
                  <div className="col-12 mb-4">
                    <CategoryProduct
                      list={listCategories}
                      errors={errors} 
                      {...register('category', {
                        required: { value: true, message: 'Selecciona una categoría' },
                      })}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <InfoProduct
                      watch={watch}
                      price={register('price', {
                        required: { value: false, message: 'Campo requerido' },
                        pattern: {value: /^\d{10}$/, message: 'Ingresa un número válido'},
                        maxLength: { value: 6, message: 'Valor muy grande (máximo 6 caracteres)' },
                        valueAsNumber: true,
                        onChange: (e) => {
                          dispatch(setProductPrice(e.target.value))
                          setValue('price', e.target.value.trim())
                        },
                        validate: value =>{
                          if(!/^\d+$/.test(value)){
                            return 'Este campo solo acepta dígitos'
                          }
                        }
                      })}
                      discountedPrice={register('discountedPrice', {
                        required: false,
                        pattern: {value: /^\d+$/,message: 'Este campo solo acepta dígitos'},
                        onChange: e => {
                          let value = e.target.value
                          dispatch(setProductDiscount(value))
                          setValue('discountedPrice', value.trim())
                        },
                        validate: (value) => {
                          let normalPrice = parseInt(watch('price'))
                          let currentPrice = parseInt(value)
                          if (normalPrice < currentPrice) {
                            return 'El precio en descuento debe ser menor al precio normal'
                          }
                          clearErrors('discountedPrice')
                          return true
                        }
                      })}
                      errors={errors}
                      counterDescription={description.length}
                      registerNameProduct={register('registerNameProduct', {
                        required:{value: true, message: 'Campo requerido'},
                        minLength:{value: 3, message:'Verifica el título del producto'},
                        maxLength:{value: 40, message:'Título demasiado largo'},
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: 'No se permiten espacios en blanco en el título',
                        },
                        onChange: e => dispatch(setNewTitle(e.target.value)),
                      })}
                      registerDescription={register('registerDescription', {
                        required:{value: true, message: 'Ingresa los ingredientes'},
                        minLength:{value: 3, message:'Describe mejor tu producto'},
                        maxLength:{value: 230, message:'Descripción muy extensa'},
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: 'No se permiten espacios en blanco en la descripción',
                        },
                        onChange: e => dispatch(setNewDescription(e.target.value)),
                      })}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <Chilli
                      registerChilli={register('registerChilli', {
                        onChange: e => dispatch(setChilli(e.target.checked))
                      })}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <OneOption
                      errors={errors}
                      trigger={trigger}
                      watch={watch}
                      setValue={setValue}
                      registerTitleOneOption={register('registerTitleOneOption', {
                        required: {value: uniqueoption.active, message:'Campo requerido'},
                        minLength:{value: 3, message:'Título muy corto'},
                        maxLength:{value: 40, message:'Título muy largo'},
                        onChange: e =>{
                          dispatch(setUniqueOption({type:'title',value: e.target.value}))
                        }
                      })}
                      nameUniqueOption={register('nameUniqueOption', {
                        required: {value: (uniqueoption.active && uniqueoption.op.length === 0) ? true: false , message:'Campo requerido'},
                        minLength:{value: 3, message:'Nombre muy corto'},
                        maxLength:{value: 40, message:'Nombre muy largo'}
                      })}
                      valueUniqueOption={register('valueUniqueOption', {
                        required: false,
                        maxLength:{value: 7, message:'Valor muy grande'},
                        pattern: {value: /^\d+$/, message: 'Este campo solo acepta dígitos'},
                      })}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <MultipleOption
                      errors={errors}
                      trigger={trigger}
                      watch={watch}
                      setValue={setValue}
                      registerTitleMultipleOption={register('registerTitleMultipleOption', {
                        required: {value: multipleoption.active , message:'Campo requerido'},
                        minLength:{value: 3, message:'Título muy corto'},
                        maxLength:{value: 40, message:'Título muy largo'},
                        onChange: e =>{
                          dispatch(setMultipleOption({type:'title',value: e.target.value}))
                        }
                      })}
                      nameMultipleOption={register('nameMultipleOption', {
                        required: {value:(multipleoption.active && multipleoption.op.length === 0) ? true: false, message:'Campo requerido'},
                        minLength:{value: 3, message:'Nombre muy corto'},
                        maxLength:{value: 40, message:'Nombre muy largo'}
                      })}
                      valueMultipleOption={register('valueMultipleOption', {
                        valueAsNumber: true,
                        required: false,
                        maxLength:{value: 7, message:'Valor muy grande'},
                        pattern: {value: /^\d+$/, message: 'Este campo solo acepta dígitos'},
                      })}
                      registerCountMultiple={register('registerCountMultiple', {
                        required: {value:multipleoption.active, message:'Campo requerido'},
                        onChange: e =>{
                          setValue('registerCountMultiple',e.target.value)
                          dispatch(setMultipleOption({type:'max',value: parseInt(e.target.value)}))
                        }
                      })}
                    />
                  </div>
                  <div className="col-12 mb-5 pb-5">
                    <AditionalComments
                      registerComments={register('registerComments', {
                        onChange: e =>{
                          dispatch(setComments(e.target.checked))
                        }
                      })}
                    />
                  </div>
                </div>
                {updateProductoFile.length > 0 && (
                  <div className="centerSubmit saveRight">
                    <button type='submit' className='btnSave'>Guardar</button>
                  </div>
                )}
                <div className='btnView'>
                  <button type='button'
                    data-bs-toggle="modal"
                    data-bs-target="#modalViewProduct" />
                </div>
              </div>
              <div className="col-md-4 mb-5 mb-md-0 d-none d-md-block">
                <Viewer />
              </div>
            </div>
          </form>
        </>
      ) : <FullProducts max={maxProductLength} />}
    </FormProvider>
  )
})

export default CreateProduct

const FullProducts = ({max}) => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-7">
          <div className="bg-b1 rounded-3 my-5 p-4 text-center">
            <h5 className="text-apple mb-4">Tienes {max} productos registrados en tu eCommerce</h5>
            <p>Llegaste al límite de inscripción de productos, nos <strong>alegra mucho</strong> que estés aprovechando al máximo nuestra plataforma.</p>
            <p>No muchas plataformas logran diversificar y expandir tanto su portafolio de productos como lo lograste tú, si ves necesario ampliar aún más este rango por motivos especiales a tu actividad económica, <strong>recuerda que estamos aquí para escucharte</strong> y llegar a una negociación que benefice ambas partes </p>
            <div className="row justify-content-center">
              <div className="col-md-5">
                <a
                  href="https://api.whatsapp.com/send?phone=573125599832&text=Hola,%20quisiera%20ampliar%20la%20capacidad%20de%20los%20servicios%20de%20mi%20men%C3%BA%20digital"
                  className='btnPurple'
                  target='_blank'
                  rel='noreferrer'
                >¡Negociemos!</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
