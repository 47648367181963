import React, { useEffect, useState, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import $ from 'jquery';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      labels: { color: 'white' }
    },
    title: {
      display: true,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: { color: 'white' },
    },
    y: {
      beginAtZero: true,
      ticks: {
        color: 'white',
        stepSize: 50000,
      },
    },
  },
};

const ErningsGraph = ({ orderReports }) => {
  const selectRef = useRef(null);
  const currentWeek = moment().week();

  const [data, setData] = useState({
    labels: ['Dom', 'Lun', 'Mar', 'Miér', 'Jue', 'Vier', 'Sab'],
    datasets: [
      {
        label: 'Ventas diarias',
        data: Array(7).fill(0),
        borderColor: '#83C232',
        backgroundColor: '#83C232',
      },
    ],
  });

  const [weeklyData, setWeeklyData] = useState({});
  const [selectedWeek, setSelectedWeek] = useState(currentWeek);

  useEffect(() => {
    if (orderReports) {
      const allWeeksData = {};

      Object.values(orderReports).forEach(order => {
        const orderDate = moment(order.client.date, 'DD/MMM/YYYY HH:mm A');
        const orderWeek = orderDate.week();
        const dayOfWeek = orderDate.isoWeekday() % 7; 

        if (!allWeeksData[orderWeek]) {
          allWeeksData[orderWeek] = Array(7).fill(0);
        }

        const state = order.client.state;
        if (state === 1 || state === 2 || state === 3 || state === 4) {
          const orderTotal = order.order.reduce((sum, item) => sum + (item.price * item.count), 0);
          allWeeksData[orderWeek][dayOfWeek] += orderTotal;
        }
      });

      setWeeklyData(allWeeksData);
    }
  }, [orderReports]);

  useEffect(() => {
    if (weeklyData[selectedWeek]) {
      setData(prevData => ({
        ...prevData,
        datasets: prevData.datasets.map(dataset => ({
          ...dataset,
          data: weeklyData[selectedWeek],
        })),
      }));
    }
  }, [selectedWeek, weeklyData]);

  useEffect(() => {
    if (selectRef.current) {
      $(selectRef.current).select2({
        width: '100%',
        dropdownCssClass: 'select2-custom-dropdown',
        language: {
          noResults: function () {
            return "No hay reportes para esos parámetros de búsqueda";
          }
        }
      }).val(selectedWeek).trigger('change');

      $(selectRef.current).on('change', (event) => {
        setSelectedWeek(parseInt(event.target.value));
      });
    }
  }, [weeklyData]);

  useEffect(() => {
    if (selectRef.current) {
      $(selectRef.current).val(currentWeek).trigger('change');
    }
  }, [selectRef.current]);

  const getWeekRange = (week) => {
    const startOfWeek = moment().week(week).startOf('week').format('DD/MM/YYYY');
    const endOfWeek = moment().week(week).endOf('week').format('DD/MM/YYYY');
    return `${startOfWeek} - ${endOfWeek}`;
  };

  const availableWeeks = Object.keys(weeklyData).map(week => (
    <option key={week} value={week}>
      Semana {week} ({getWeekRange(week)})
    </option>
  ));

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <strong className="text-apple mb-1 d-block text-center text-md-start">Ventas diarias de la semana</strong>
          <small className="text-white d-block text-center text-md-start mb-4 mb-md-0">Clasificación productos terminados</small>
        </div>
        <div className="col-md-6">
          <select ref={selectRef} defaultValue={selectedWeek || currentWeek}>
            {availableWeeks}
          </select>
        </div>
      </div>
      <div className="graphResumeSell">
        <Bar options={options} data={data} />
      </div>
    </>
  );
}

export default ErningsGraph;
