import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListOrderInDash from './ListOrderInDash'
import { convertAmount } from '../../../tools/format'
import { customizeOrder, resetOrderInDash, setOrderMethods } from '../../../../redux/reducer/ordering'
import { setInteriorMenu, setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions'
import addInOrder from '../../../../firebase/update/addInOrder'
import uniqid from 'uniqid'
import { format } from 'date-fns'
import { addOrderLink } from '../../../../firebase/credentials'



const PendingOrder = () => {

  const {phone: IDMENU, indicative, name, email} = useSelector(s => s.log)
  const {priceDelivery} = useSelector(s => s.menu)
  const {order, idOrderSelected, orderProducts, modifyOrder,dataClient, orderMethods} = useSelector(s => s.ordering)
  const [totalValue, setTotalValue] = useState(0)
  const dispatch = useDispatch()

  useEffect(()=>{
    if(order){
      let copy = JSON.parse(JSON.stringify(order))
      const total = copy.reduce((accumulator, item) => {
        let itemValue = item.price * item.count
        return accumulator + itemValue
      }, 0)
      setTotalValue(total)
    }
  },[order])

  const handleMinus = (index)=>{
    dispatch(customizeOrder({index, type: 'minus'}))
  }


  const handleAdd = (index)=>{
    dispatch(customizeOrder({index, type: 'add'}))
  }

  const handleDeleteAll = ()=>{
    dispatch(resetOrderInDash())
  }
  
  
  const handleAddInOrder = async()=>{
    const reset = document.getElementById('handleResetAll')
    let newOrder = order.concat(orderProducts)
  
    dispatch(setStateLoading(true))
    if(modifyOrder){
      const response = await addInOrder(indicative, IDMENU,idOrderSelected,newOrder)
      if(response){
        dispatch(resetOrderInDash())
        dispatch(setTextNotificate('Productos añadidos a la orden'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false)) 
        reset && reset.click()
        dispatch(setOrderMethods({}))
        if(document.getElementById('resetModalOrderInMenuM')){document.getElementById('resetModalOrderInMenuM').click()}
      }else{
        dispatch(setTextNotificate('¡Ha ocurrido un error, inténtalo mas tarde!'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true)) 
      }
    }else{
      const currentDate = new Date()
      const formattedDate = format(currentDate, 'dd/MMM/yyyy HH:mm a')

      const orderClient = {
        client: {
          name: dataClient?.name || '',
          phone:  parseInt(dataClient?.tel) || null,
          address: dataClient?.address || '',
          neighbor: dataClient?.neighborhood || '',
          date: formattedDate,
          state: 1,
        },
        customerService: IDMENU,
        order: order,
        origin: 'Admin' ,
        op: uniqid.process().substring(0, 10),
        payment: {
          delivery: orderMethods?.deliveryType === 'domicilio' ? true: false,
          deliveryPrice: orderMethods?.deliveryType === 'domicilio' ? priceDelivery : 0,
          method: orderMethods?.payment || 'Efectivo',
          discount: 0,
          total: totalValue + priceDelivery,
        },
        admin: {
          name,
          email,
        }
      }


      const {success, error} = await addOrderLink(indicative, IDMENU, orderClient)
      if(success){
        dispatch(setTextNotificate('Productos añadidos a la orden'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false)) 
      }else{
        dispatch(setTextNotificate('Ha ocurrido un error, inténtalo mas tarde 1111111'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true)) 
        console.log(error)
      }
      
    }
    dispatch(resetOrderInDash())
    reset && reset.click()
    dispatch(setStateLoading(false))
    dispatch(setInteriorMenu(false))
  }

  return (
    <div className='p-4'>
      <div className='d-flex align-items-center mb-3'>
        <svg className="icon me-2" fill='white' id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 26">
          <path d="m25.71,8.33l-.31-3.07c-.46-3.33-1.97-4.69-5.22-4.69h-8.48s0,0-.01,0c0,0,0,0-.01,0h-3.37C5.06.57,3.55,1.93,3.08,5.29l-.31,3.04c-.11,1.11.18,2.17.79,3.04-.02.07-.04.14-.04.22v4.31c-.09.06-.2.12-.28.19-1.25.99-1.97,2.49-1.97,4.09,0,.96.26,1.9.75,2.68.11.2.26.41.43.61.03.04.06.08.09.1.99,1.18,2.44,1.85,3.98,1.85,1.24,0,2.41-.44,3.29-1.18.46.05.94.08,1.5.08h5.95c5.45,0,7.78-2.33,7.78-7.78v-4.96c0-.1-.02-.19-.06-.28.57-.86.85-1.88.74-2.97Zm-1.96-2.87l.31,3.04c.08.84-.16,1.62-.69,2.21-.53.58-1.29.91-2.13.91-1.39,0-2.64-1.12-2.82-2.51,0-.14.01-.27,0-.41l-.65-6.47h2.41c2.42,0,3.23.73,3.57,3.23Zm-7.6,5.36c-.94,1.04-2.82,1.03-3.76,0-.47-.52-.69-1.21-.62-1.95l.67-6.63h3.62l.7,7s0,.03.01.05c-.03.58-.24,1.12-.62,1.53Zm-11.73-2.32l.31-3.01c.35-2.53,1.15-3.26,3.58-3.26h2.45l-.68,6.83c-.13,1.38-1.43,2.55-2.82,2.55-.84,0-1.6-.33-2.14-.91-.53-.59-.78-1.37-.7-2.21Zm-.57,14.07c-.03-.05-.07-.1-.12-.14,0,0,0-.01-.01-.02-.1-.11-.19-.23-.28-.39-.34-.55-.52-1.19-.52-1.84,0-1.1.49-2.12,1.36-2.81.14-.12.29-.22.48-.32,1.3-.74,2.94-.59,4.13.44.09.07.17.15.25.24.62.68.96,1.55.96,2.45,0,.66-.18,1.29-.52,1.85-.18.3-.37.54-.59.72-.05.04-.09.08-.13.13-.64.57-1.47.89-2.35.89-1.05,0-2.04-.46-2.66-1.2Zm13.4.09h-5.95c-.06,0-.12,0-.18,0,.42-.76.64-1.61.64-2.48,0-1.31-.5-2.58-1.4-3.56-.14-.16-.31-.32-.43-.41-.96-.83-2.14-1.28-3.42-1.28-.45,0-.9.06-1.34.18v-2.33c.63.32,1.33.5,2.08.5,1.42,0,2.74-.72,3.57-1.82.11.16.21.32.34.47.79.87,1.89,1.35,3.11,1.35s2.31-.48,3.1-1.34c.12-.14.22-.29.32-.44.84,1.07,2.15,1.78,3.54,1.78.77,0,1.49-.2,2.13-.53v3.8c0,4.52-1.6,6.12-6.12,6.12Z"/>
          <path d="m8.16,19.33h-.82v-.78c0-.46-.37-.83-.83-.83s-.83.37-.83.83v.78h-.82c-.46,0-.83.37-.83.83s.37.83.83.83h.82v.86c0,.46.37.83.83.83s.83-.37.83-.83v-.86h.82c.46,0,.83-.37.83-.83s-.37-.83-.83-.83Z"/>
        </svg>
        <strong>Tu pedido</strong>
        <div className="labelP text-apple">{order?.length} {order?.length === 1 ? 'Producto': 'Productos'}</div>
      </div>

      <div className={`contentProductsInDash ${order?.length>0 ? 'active': ''}`}>
        {
          order?.length === 0 ? (
            <><p className='emptyCarInDash'>No hay productos que agregar</p></>
          ): (
            <>
              {
                order.map((el, index) => (
                  <ListOrderInDash
                    key={`${el.title}-${index}`}
                    title={el.title}
                    oneOption={el.oneOption}
                    multipleTitle={el.multipleTitle}
                    img={el.img}
                    total={el.price}
                    counter={el.count}
                    comments={el.comments}
                    handleMinus={()=> handleMinus(index)}
                    handleAdd={()=> handleAdd(index)}
                  />
                ))
              }
            </>
          )
        }
      </div>

      <div className="footerCard">
          {
            order?.length  > 0 && (
              <>
                <div className="d-block d-md-none py-3">
                  <div className="row justify-content-center">
                    <div className="col-7 ">
                      <button
                        type='button'
                        className="btn-orange"
                        onClick={handleDeleteAll}
                        disabled={order.length >0 ? false: true}
                      >Vaciar todo</button>
                    </div>
                  </div>
                </div>
              </>
            )
          }
          <div className="totalPriceInOrder">
            <strong>Subtotal</strong>
            <strong className="price">{convertAmount(totalValue)}</strong>
          </div>
          <div className="row mt-4 d-none d-md-flex">
            <div className="col-6">
              {
                order?.length >0 && 
                  <button
                    type='button'
                    className="btn-orange"
                    onClick={handleDeleteAll}
                  >Vaciar todo</button>
              }
            </div>
            {order?.length !== 0 && (
              <>
                <div className="col-6">
                  <button
                    type='button'
                    className={`btn-line-apple ${order?.length > 0 ? '': 'disabled'}`}
                    onClick={handleAddInOrder}
                  >Confirmar pedido</button>
                </div>
              </>
            )}
          </div>
          {
            order?.length > 0  && (
              <div className="footerMobileOrderInMenu">
                <button
                  className='btnModalConfirm'
                  onClick={handleAddInOrder}
                  >{modifyOrder ? 'AGREGAR A LA ORDEN': 'HACER PEDIDO'}</button>
              </div>
            )
          }
        </div>



    </div>
  )
}

export default PendingOrder