import { useEffect, useState } from "react";

const useNotificationPermission = () => {
  const [permission, setPermission] = useState(Notification.permission);

  useEffect(() => {
    setPermission(Notification.permission);
  }, []);

  const requestPermission = () => {
    Notification.requestPermission().then((perm) => {
      setPermission(perm);
    });
  };

  return { permission, requestPermission };
};

export default useNotificationPermission;
