import { db } from "../credentials"
import {updateDoc, doc} from 'firebase/firestore'



const setStateOrder = async(country, number ,obj, value) => {
  
  const ref = doc(db, process.env.REACT_APP_ORDERLINKS, `${country}${number}`)
  try {
    await updateDoc(ref, {
      [`${obj}.client.state`]: value
    } )
    return true
  } catch (error) {
    return false
  }
}

export default setStateOrder