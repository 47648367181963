import React from 'react'
import Global from './Global'
import PersonalReport from './PersonalReport'
import SellGraph from './SellGraph'
import ErningsGraph from './ErningsGraph'
import ProductsReport from './ProductsReport'
import { useSelector } from 'react-redux'
import DownloadReport from './DownloadReport'
import emptyImage from '../../../../img/dashboard/empty_reports.svg'
import InfoGreen from '../InfoGreen'
import Table from './Table'
import ModalWatchOrder from './ModalWatchOrder'

const HomeReports = () => {



  const {orderReports} = useSelector(s => s.log)



  return (
    <>
      <section className="p-3 mb-5 mb-md-0 sectionThemeDash">
        {
          orderReports.length === 0 ? (
            <div className='p-2 p-md-5'>
              <div className="row justify-content-center">
                <div className="col-md-7">
                  <h5 className="text-center text-apple mb-4">No tienes reportes este mes</h5>
                  <img src={emptyImage} alt="empty" className='img-fluid'/>
                  <InfoGreen
                    text='Este módulo se activará una vez recibas el primer pedido. Recuerda que tienes la opción de descargar tu reporte de ventas en todo momento.'
                  />
                </div>
              </div>
            </div>
          ):
          (    
            <div className="row">
              <div className="col-md-4 mt-4 mt-md-0">
                <div className="mb-3 text-center text-md-start d-none d-md-block">
                </div>
                <Global orderReports={orderReports} />
                <PersonalReport orderReports={orderReports} />
                <ProductsReport orderReports={orderReports} />
              </div>
              <div className="col-md-8 order-first order-md-last">
                <div className="row ">
                  <div className="col-12">
                    <SellGraph  orderReports={orderReports}/>
                  </div>
                  <div className="col-md-12 mt-5">
                    <ErningsGraph orderReports={orderReports}/>
                  </div>
                  <div className="col-12">
                    <DownloadReport orderReports={orderReports} />
                  </div>
                </div>
              </div>
            </div>
          )

        }
        <Table  data={orderReports} />
      </section>
      <ModalWatchOrder/>
    </>
  )
}

export default HomeReports