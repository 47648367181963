import React, { useEffect, useState } from 'react'
import cat from '../../../img/brand/space_cat.webp'
import { useSelector } from 'react-redux'
import updateOnlyOnePiece from '../../../firebase/update/updateOnlyOnePiece'

const FailCredits = () => {

  const {paymentState, name, userID} = useSelector(s => s.log)
  const [message, setMessage] = useState("")

  useEffect(()=>{
    if(paymentState.c === "a"){
      setMessage('1 año plan PREMIUM')
    }
    if(paymentState.c === "b"){
      setMessage('1 año plan CORPORATIVO')
    }
    if(paymentState.c === "c"){
      setMessage('6 meses plan PREMIUM')
    }
    if(paymentState.c === "d"){
      setMessage('6 meses plan CORPORATIVO')
    }
  },[paymentState])
  

  const settings = {
    a: false,
    b: false,
    c: ""
  }

  const closeModal = async () =>{
    await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAUSER, 'p', settings)
  }

  return (
    <>
    {
      paymentState.b && (

        <section className="popupCredits error">
          <div className="content">
            <button
              type='button'
              className="closeModal"
              onClick={closeModal}
            />
            <div className="row">
              <div className="col-md-6 align-self-center">
                <div className="p-4">
                  <div className="">
                    <h2>¡Ooops!</h2>
                    <h5>Hola {name}, no fué posible actualizar tu Licencia Fooder de {message}.</h5>
                  </div>
                  <p className="mt-3 mb-4">Verifica tus datos nuevamente en la pasarela de pagos Bold. Si el error persiste no dudes en escribirnos al <br className='d-none d-md-block'/> <a href="https://api.whatsapp.com/send?phone=573125599832&text=Hola%20%C2%BFque%20tal?,%20he%20tenido%20problemas%20al%20recargar%20mis%20Cr%C3%A9ditos%20Fooder" rel='noopener noreferrer' className='text-link text-dark d-inline' target='_blank'>312 559 9832</a></p>
                  <div className="mt-4 mt-md-3">
                    <button 
                      type='button'
                      className="btnWhite w-100"
                      onClick={closeModal}
                    >Cerrar</button>
                  </div>
                </div>
              </div>
              <div className="col-md-6 align-self-center order-first order-md-last text-center">
                <img
                  src={cat}
                  alt=""
                  className='img-fluid cat'
                />
              </div>
            </div>
          </div>
        </section>

      )
    }
    </>
  )
}

export default FailCredits
