import React from 'react'
import { useSelector } from 'react-redux'
import payment from '../../../../../img/vector/cash_module.svg'
import updateOnlyOnePiece from '../../../../../firebase/update/updateOnlyOnePiece'

const Cash = () => {

  const {cash,userID} = useSelector( s=> s.log)

  const switchCash = async(e) =>{
    await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAUSER, 'x', e.target.checked)
  }

  return (
    <div className="bg-b1 rounded-lg p-4 mt-3">
      <div className="row">
        <div className="col-12">
          <div className="row justify-content-center">
            <div className="col-md-10 align-self-center">
              <div className='d-flex align-self-center mb-4 mb-md-0'>
                <div className="form-check form-switch justify-content-center">
                  <input
                    className="form-check-input me-3"
                    type="checkbox"
                    role="switch"
                    defaultChecked={cash} 
                    onChange={switchCash}
                  />
                </div>
                <span className='text-white d-flex align-items-center'>Pagos en efectivo </span>
              </div>
            </div>
            <div className="col-md-2 col-6 align-items-center">
              <img src={payment} alt="" className='img-fluid'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cash