import React from 'react'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatQuantity } from '../../../tools/formatQuantity'


Chart.register(ArcElement, Tooltip, Legend)


const CardsNovelties = () => {

  const {listProducts, listCategories} = useSelector(state => state.menu)
  const {visitCounter, cuota, printedPage} = useSelector(s => s.log)
  const {maxProductLength, limitCategories, customerListLimit,customerList} = useSelector(state => state.log)

  const productsData = {
    datasets: [
      {
        data: [
          ((listProducts?.length * 100) / maxProductLength), 
          (100 - ((listProducts?.length * 100) / maxProductLength))
        ],
        backgroundColor: ['#8bf4ac', '#4c4b56'],
        borderWidth: 0,
      },
    ],
  };

  const categoriesData = {
    datasets: [
      {
        data: [((listCategories?.length * 100)/limitCategories), (100-((listCategories?.length * 100)/limitCategories))],
        backgroundColor: ['#8bf4ac', '#4c4b56'],
        borderWidth: 0,
      },
    ],
  };
 

  //-------------------------------------------------------------------
  
  const customerData = {
    datasets: [
      {
        data: [((customerList?.length * 100)/customerListLimit), (100-(((customerList?.length * 100)/customerListLimit)))],
        backgroundColor: ['#8bf4ac', '#4c4b56'],
        borderWidth: 0,
      },
    ],
  };
  
  const productRange = {
    cutout: '85%',
    events: [], 
    plugins: {
      tooltip: {
        enabled: false, 
      },
    },
  };

  //-------------------------------------------------------------------

  return (
    <section>
      <div className="row mt-3">
        <div className="col-md-6 mb-3 ">
          <div className="bg-b1 rounded-2 p-3 p-md-4">
            <div className="row">
              <div className="col-7 align-self-center">
                <div>
                  <h6 className='text-apple'>Cantidad de productos</h6>
                  <p className='mb-0'>Puedes ampliar la capacidad de inscripción de tus productos según el plan.</p>
                </div>
                {
                  maxProductLength === listProducts?.length && (
                    <Link 
                      to='https://api.whatsapp.com/send?phone=573125599832&text=Hola%20!%20quisiera%20ampliar%20mi%20plan%20b%C3%A1sico%20FOODER.'
                      target='_blank'
                      className='text-link-apple'
                    >Actualizar plan</Link>
                  )
                }
              </div>
              <div className="col-5 pb-2">
                <div className="circularBARSection">
                  <Doughnut data={productsData || null} options={productRange || null} />
                  <div className="total">
                    <span className="text-aqua">{listProducts?.length}</span>
                    <span>/{maxProductLength || 0}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3 ">
          <div className="bg-b1 rounded-2 p-3 p-md-4">
            <div className="row">
              <div className="col-7 align-self-center">
                <h6 className='text-apple'>Categorías del plan</h6>
                <p className='mb-0'>El número de categorías facilita al usuario filtrar los productos de tu menú.</p>
              </div>
              <div className="col-5 pb-2">
                <div className="circularBARSection">
                  <Doughnut data={categoriesData || null} options={productRange || null}/>
                  <div className="total">
                    <span className="text-aqua">{listCategories?.length}</span>
                    <span>/{limitCategories || null}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3 ">
          <div className="bg-b1 rounded-2 p-3 p-md-4">
            <div className="row">
              <div className="col-7 align-self-center">
                <h6 className='text-apple'>Libreta de clientes</h6>
                <p className='mb-0'>Aprovecha esta herramienta al máximo para mejorar la atención al cliente en las llamadas que recibes.</p>
              </div>
              <div className="col-5 pb-2">
                <div className="circularBARSection">
                  <Doughnut data={customerData || null} options={productRange || null}/>
                  <div className="total">
                    <span className="text-aqua">{customerList?.length}</span>
                    <span>/{customerListLimit || null}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="bg-b1 rounded-2 p-3 p-md-4 h-100">
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="row w-100">
                <div className="col-7 align-self-center ">
                  <h6 className='text-apple'>Visitas totales</h6>
                  <p className='m-0'>No olvides crear post y estados para que más clientes conozcan tu eCommerce.</p>
                </div>
                <div className="col-5 pb-2 align-self-center">
                  <h3 className="text-aqua mb-2 text-center">{formatQuantity(visitCounter)}</h3>
                </div>
                <div className="col-12">
                  <hr />
                </div>
                {
                  cuota === 3 && (
                    <div className="col-12 align-self-center ">
                      <div className="d-flex align-items-center mb-2">
                        <h6 className='text-apple mb-1 me-3'>Visitas diarias</h6>
                        <small>{(800 - printedPage)} de 800 posibles</small>
                      </div>
                      <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar" style={{width: `${((800 - printedPage)*100)/800}%`}}><strong>{(((800 - printedPage)*100)/800).toFixed(1)}%</strong></div>
                      </div>
                    </div>
                  )
                }

                {
                  cuota === 2 && (
                    <div className="col-12 align-self-center ">
                      <div className="d-flex align-items-center mb-2">
                        <h6 className='text-apple mb-1 me-3'>Visitas diarias</h6>
                        <small>{(300 - printedPage)} de 300 posibles</small>
                      </div>
                      <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar" style={{width: `${((300 - printedPage)*100)/300}%`}}><strong>{(((300 - printedPage)*100)/300).toFixed(1)}%</strong></div>
                      </div>
                    </div>
                  )
                }
                
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CardsNovelties