import { db } from "../../../../firebase/credentials"
import { doc, updateDoc } from "firebase/firestore"

const updateStateRequest = async(tel) =>{

  const ref = doc(db, process.env.REACT_APP_SUDOINSPECT, tel)

  try {
    await updateDoc(ref, {
      solve: true
    })
    return true
  } catch (error) {
    return false
  }

}

export default updateStateRequest

