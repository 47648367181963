import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import EmptyCart from './EmptyCart'
import ListOrderCheckout from './ListOrderCheckout'
import {  setCleanOrder, setActiveTYC, setLoadMenu, setDataClient } from '../../../../redux/reducer/user'
import {  encriptMenuPayment, temporalData } from '../../../../firebase/credentials'
import { format } from 'date-fns'
import { setAlmostFinish } from '../../../../redux/reducer/menu'
import 'swiper/swiper-bundle.css'
import ConfirmOrder from './ConfirmOrder'
import FormClient from './FormClient'
import ChooseModal from './ChooseModal'
import { resetPaymentData, setBoldData, setOp } from '../../../../redux/reducer/ordering'




const Checkout = () => {

  const swiperRef = useRef(null)
  const {clientAddress, menu, order, admin, showTYC, userID } = useSelector(s => s.user)
  const {op} = useSelector(s => s.ordering)
  const [isDelivery, setIsDelivery] = useState(false)
  const [errorSent, setErrorSent] = useState(false)
  const [totalValue, setTotalValue] = useState(0)
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(setOp()) 
  },[])

  useEffect(()=>{
    let copy = JSON.parse(JSON.stringify(order))
    const total = copy.reduce((accumulator, item) => {
      let itemValue = item.price * item.count
      return accumulator + itemValue
    }, 0)
    setTotalValue(total)
  },[order])

  const resetBold = () => {
    const container = document.getElementById('bold-button-container');
    if (container) {
      container.remove()
    }
  };

  const handleCloseModal = ()=>{
    window.location.reload()
  }


  const handleOP1 = ()=>{
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
      setIsDelivery(true)
    }
  }
  
  const handleOP2 = ()=>{
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
      setIsDelivery(false)
    }
  }
  
  const getBack = ()=>{
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
      setIsDelivery(false)
      dispatch(resetPaymentData())
      resetBold()
    }
  }
  const backToUserInfo = ()=>{
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
      dispatch(resetPaymentData())
      resetBold()
    }
  }

  const settings ={
    spaceBetween: 0,
    slidesPerView: 1,
    draggable: false,
    mousewheel: false,
    loop: false,
    allowTouchMove: false,
  }




  const {register: registerData, handleSubmit: handleSubmitData, formState: { errors },reset, watch } = useForm({
    mode: 'onChange'
  })

  const handleSendOrder = async()=>{

    dispatch(setLoadMenu(true))
    const currentDate = new Date()
    const formattedDate = format(currentDate, 'dd/MMM/yyyy HH:mm a')
    
    const totalOrder = isDelivery ? totalValue + menu?.r : totalValue
    
    const orderClient = {
      client: {
        name: watch('userName') || '',
        phone:  parseInt(watch('userTel')) || null,
        address: watch('address') || '',
        neighbor: watch('neighbor') || '',
        date: formattedDate,
        country: menu?.s,
        state: 0,
      },
      customerService: menu?.h,
      order: order,
      origin: 'Menú digital' ,
      op,
      payment: {
        delivery: isDelivery,
        deliveryPrice: isDelivery ? parseInt(menu?.r) : 0,
        method: null,
        discount: 0,
        total: totalOrder,
      },
      admin: {
        name: admin?.a,
        email: admin?.e,
      }
    }
    dispatch(setDataClient(orderClient))
    await temporalData(menu?.s, admin?.f, orderClient)
    dispatch(setLoadMenu(false))
  }



  const cleanWhileSentOrder = ()=>{
    dispatch(setLoadMenu(false))
    setErrorSent(false)
    dispatch(setCleanOrder())
    dispatch(setAlmostFinish(true))
    reset()
  }

  const getBackFromConfirmOrder = ()=>{
    if(swiperRef.current && swiperRef.current.swiper){
      swiperRef.current.swiper.slideTo(0)
    }
  }

  const submitData = async() =>{
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
      handleSendOrder()
      let sign;
      let apiKey;
      const price = totalValue + menu.r
      const paymentID = `${admin.k}${admin.f}_${price}_${op}`

      
      const signature = await encriptMenuPayment({
        idSale: paymentID,
        amount: price.toString(),
        currency: "COP",
        userID,
        testingMode: !admin.w.b
      });

      if (signature.success) {
        sign = signature.hash

        if(!admin.w.b){
          apiKey = admin.w.c
        }else{
          apiKey = admin.w.d
        }
  
        const bold = {
          sign, apiKey, price, paymentID
        }
        dispatch(setBoldData(bold))

      }
    }
  }


  
  

  return (
    <>
      <div className="modal fade modalCheckout" id="checkoutModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="checkoutModalLabel" aria-hidden="true" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="checkoutModalLabel">Checkout</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id='closeMCheckout'
                onClick={handleCloseModal}
              ></button>
              <button
                type="button"
                className="d-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                id='closeMCheckout2'
              ></button>
            </div>
            <div className="modal-body">
              <div className="contentCheckout">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4 align-self-center">
                      <div className="d-none d-md-block">
                        <div className='d-flex align-items-center mb-3'>
                          <svg className="icon me-2" fill={menu?.g?.[3]} id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 26">
                            <path d="m25.71,8.33l-.31-3.07c-.46-3.33-1.97-4.69-5.22-4.69h-8.48s0,0-.01,0c0,0,0,0-.01,0h-3.37C5.06.57,3.55,1.93,3.08,5.29l-.31,3.04c-.11,1.11.18,2.17.79,3.04-.02.07-.04.14-.04.22v4.31c-.09.06-.2.12-.28.19-1.25.99-1.97,2.49-1.97,4.09,0,.96.26,1.9.75,2.68.11.2.26.41.43.61.03.04.06.08.09.1.99,1.18,2.44,1.85,3.98,1.85,1.24,0,2.41-.44,3.29-1.18.46.05.94.08,1.5.08h5.95c5.45,0,7.78-2.33,7.78-7.78v-4.96c0-.1-.02-.19-.06-.28.57-.86.85-1.88.74-2.97Zm-1.96-2.87l.31,3.04c.08.84-.16,1.62-.69,2.21-.53.58-1.29.91-2.13.91-1.39,0-2.64-1.12-2.82-2.51,0-.14.01-.27,0-.41l-.65-6.47h2.41c2.42,0,3.23.73,3.57,3.23Zm-7.6,5.36c-.94,1.04-2.82,1.03-3.76,0-.47-.52-.69-1.21-.62-1.95l.67-6.63h3.62l.7,7s0,.03.01.05c-.03.58-.24,1.12-.62,1.53Zm-11.73-2.32l.31-3.01c.35-2.53,1.15-3.26,3.58-3.26h2.45l-.68,6.83c-.13,1.38-1.43,2.55-2.82,2.55-.84,0-1.6-.33-2.14-.91-.53-.59-.78-1.37-.7-2.21Zm-.57,14.07c-.03-.05-.07-.1-.12-.14,0,0,0-.01-.01-.02-.1-.11-.19-.23-.28-.39-.34-.55-.52-1.19-.52-1.84,0-1.1.49-2.12,1.36-2.81.14-.12.29-.22.48-.32,1.3-.74,2.94-.59,4.13.44.09.07.17.15.25.24.62.68.96,1.55.96,2.45,0,.66-.18,1.29-.52,1.85-.18.3-.37.54-.59.72-.05.04-.09.08-.13.13-.64.57-1.47.89-2.35.89-1.05,0-2.04-.46-2.66-1.2Zm13.4.09h-5.95c-.06,0-.12,0-.18,0,.42-.76.64-1.61.64-2.48,0-1.31-.5-2.58-1.4-3.56-.14-.16-.31-.32-.43-.41-.96-.83-2.14-1.28-3.42-1.28-.45,0-.9.06-1.34.18v-2.33c.63.32,1.33.5,2.08.5,1.42,0,2.74-.72,3.57-1.82.11.16.21.32.34.47.79.87,1.89,1.35,3.11,1.35s2.31-.48,3.1-1.34c.12-.14.22-.29.32-.44.84,1.07,2.15,1.78,3.54,1.78.77,0,1.49-.2,2.13-.53v3.8c0,4.52-1.6,6.12-6.12,6.12Z"/>
                            <path d="m8.16,19.33h-.82v-.78c0-.46-.37-.83-.83-.83s-.83.37-.83.83v.78h-.82c-.46,0-.83.37-.83.83s.37.83.83.83h.82v.86c0,.46.37.83.83.83s.83-.37.83-.83v-.86h.82c.46,0,.83-.37.83-.83s-.37-.83-.83-.83Z"/>
                          </svg>
                          <strong>Tu pedido</strong>
                          <div className="labelP">{order?.length}  {order?.length === 1 ? 'Producto': 'Productos'}</div>
                        </div>
                        <div className={`contentProducts  ${order?.length>0 ? 'active': ''}`}>
                          {
                            order?.length === 0 ? (
                              <EmptyCart color={menu?.g?.[3]}/>
                            ): (
                              <>
                                {
                                  order.map((el, index) => (
                                    <ListOrderCheckout
                                      key={`${el.title}-${index}`}
                                      title={el.title}
                                      oneOption={el.oneOption}
                                      multipleTitle={el.multipleTitle}
                                      img={el.img}
                                      total={el.price}
                                      counter={el.count}
                                      comments={el.comments}
                                    />
                                  ))
                                }
                              </>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 align-self-center">
                      <form className='formMenuCheckout' onSubmit={handleSubmitData(submitData)} >
                        <button className="d-none" id="btnbackAlmostFinish" onClick={getBackFromConfirmOrder}/>
                        <Swiper
                          {...settings}
                          ref={swiperRef}
                        >
                          <SwiperSlide>
                            <div className="p-1">
                              <ChooseModal
                                handleOP1={handleOP1}
                                handleOP2={handleOP2}
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="p-1">
                              <FormClient
                                clientAddress={clientAddress}
                                getBack={getBack}
                                registerData={registerData}
                                errors={errors}
                                isDelivery={isDelivery}
                                admin={admin}
                                menu={menu}
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="p-1">
                              <ConfirmOrder
                                backToUserInfo={backToUserInfo}
                                totalValue={totalValue}
                                menu={menu}
                                admin={admin}
                                userID={userID}
                                dispatch={dispatch}
                                setActiveTYC={setActiveTYC}
                                handleSendOrder={handleSendOrder}
                                errorSent={errorSent}
                                cleanWhileSentOrder={cleanWhileSentOrder}
                                getBackFromConfirmOrder={getBackFromConfirmOrder}
                              />
                            </div>
                          </SwiperSlide>
                        </Swiper>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contentCheckout">
                
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-bs-dismiss="modal"
                onClick={handleCloseModal}
              >Volver al menú</button>
            </div>
          </div>
        </div>
      </div>
      <WatchTyc
        menu ={menu}
        admin={admin}
        showTYC={showTYC}
        setActiveTYC={setActiveTYC}
        dispatch={dispatch}
      />
    </>
  )
}

export default Checkout






const WatchTyc = ({showTYC, setActiveTYC, dispatch, menu})=>{

  const handleClose = ()=>{
    dispatch(setActiveTYC())
  }

  return (
    <>
      <div className={`contentTycMenu ${showTYC ? 'active': ''}`}>
        <div className="contentTyc">
        {
            menu?.m?.length >0 ? (
              <div className="contentListTYC">
                <h4 className='text-center mb-4'>Términos y condiciones</h4>
                {
                  menu.m.map((el,index)=> (
                    <div key={index}>
                      <strong>{`${index +1}.`}</strong>
                      <p className='m-0'>{el}</p>
                    </div>
                  ))
                } 
              </div>
            ): <h4 className="text-center my-4">No hay términos y condiciones disponibles en este menú</h4>
          }
        <div className="footerM">
          <button
            className="btn-confirm mb-4"
            type='button'
            onClick={handleClose}
          >Cerrar</button>
        </div>
        </div>
      </div>
    </>
  )
}