import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../credentials'


const sudo = async(key)=>{
  
  try {
    const docRef = doc(db, process.env.REACT_APP_CHECKREPORT, 'landing');
    
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const currentValue = docSnap.data()[key] || 0;
      const newValue = currentValue + 1;
      
      await updateDoc(docRef, {
        [key]: newValue,
      });

    } else {
      console.error(`El documento no existe.`);
    }
  } catch (error) {
    console.error(`Error al incrementar ${key}: `, error);
  }

}

export default sudo

