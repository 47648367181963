import React from 'react'
import { useSelector } from 'react-redux'



const InfoProduct = (
  {
    errors,
    registerNameProduct,
    counterDescription,
    registerDescription,
    price,
    discountedPrice,
    watch

  }) => {

  const {updateProductoFile} = useSelector(state => state.product)


  return (
    <>
      <div className="bg-b1 p-3 rounded-lg">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0">Información base</p>
          <span className='requiredLabel'>Requerido</span>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 mb-3">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="row justify-content-center">
                  {
                    updateProductoFile.length>0 && (
                      <div className="col-md-10">
                        <img
                          src={updateProductoFile}
                          alt="alt"
                          className='img-fluid w-100 rounded-3'
                          data-bs-toggle="modal"
                          data-bs-target="#modalProduct"
                        />
                      </div>
                    )
                  }
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="uploadBtnDash"
                      data-bs-toggle="modal"
                      data-bs-target="#modalProduct"
                    >
                      {updateProductoFile.length === 0 ? 'Subir foto' : 'Cambiar foto'}
                    </button>
                  </div>
                </div>
              
                <small className='d-block text-center mt-2 text-apple'> Formatos aceptados: .png .jpg .jpeg</small>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-6">
                <div className="inputDash bg-b2">
                  <input
                    type="text"
                    placeholder='Título del producto *'
                    className='bg-b2'
                    value={watch('registerNameProduct') || ''}  
                    autoComplete="off"
                    {...registerNameProduct}
                  />
                </div>
                {errors.registerNameProduct && <small className='errorDash'>{errors.registerNameProduct.message}</small>}
                <div className="inputDash bg-b2">
                  <input
                    type="tel"
                    placeholder='Precio normal *'
                    className='bg-b2'
                    value={watch('price') || ''} 
                    autoComplete="off"
                    {...price}
                  />
                </div>
                {errors.price && <small className='errorDash'>{errors.price.message}</small>}
                <div className="inputDash bg-b2">
                  <input
                    type="tel"
                    placeholder='Precio rebajado (opcional)'
                    className='bg-b2'
                    value={watch('discountedPrice') || ''} 
                    autoComplete="off"
                    {...discountedPrice}
                  />
                </div>
                {errors.discountedPrice && <small className='errorDash'>{errors.discountedPrice.message}</small>}
              </div>
              <div className="col-md-6">
                <textarea
                  className={`textareaDash bg-b2 ${counterDescription > 230 ? 'text-orange': ''}`}
                  rows="5"
                  value={watch('registerDescription') || ''}  
                  autoComplete="off"
                  placeholder='Descripción / ingredientes*'
                  {...registerDescription}
                />

                <div className="text-end">
                  <span className={`${counterDescription > 230 ? 'text-orange': ''}`}>{counterDescription}/230</span>
                </div>
                {errors.registerDescription && <small className='errorDash'>{errors.registerDescription.message}</small>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InfoProduct