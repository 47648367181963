import React from 'react'
import {convertAmount} from '../../../tools/format'
import defaultImage from '../../../../img/vector/defaultImage.svg'

const ListOrderCheckout = ({title,oneOption, multipleTitle, img, total, counter, comments}) => {



  return (
      <div className="cardOrder">
        <div className="row">
          <div className="col-4">
            <img
              src={img || defaultImage}
              alt={title || 'imagen'}
              className='img-fluid rounded-2'
              onError={(e) => e.target.src = defaultImage}
            />
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-12">
              <p className='m-0'>{title || 'NA'}</p>
                <p className='my-1'><small className='d-block'>{oneOption || ''}</small></p>
                <p className='mb-0'><small className='d-block'>{multipleTitle}</small></p>
                <p className="mb-2"><em><small>{comments || ''}</small></em></p>
              </div>
              <div className="col-6">
                Cant. {counter}
              </div>
              <div className="col-6">
                <strong>{convertAmount(total * counter)}</strong>
              </div>
            </div>
          </div>
        
        </div>
      </div>
  )
}

export default ListOrderCheckout