import React from 'react'
import Slider from "react-slick"
import { useSelector } from 'react-redux'
import theme1 from '../../../../img/dashboard/themes/theme1.webp'
import theme2 from '../../../../img/dashboard/themes/theme2.webp'
import theme3 from '../../../../img/dashboard/themes/theme3.webp'
import theme4 from '../../../../img/dashboard/themes/theme4.webp'
import theme5 from '../../../../img/dashboard/themes/theme5.webp'
import theme6 from '../../../../img/dashboard/themes/theme6.webp'
import theme7 from '../../../../img/dashboard/themes/theme7.webp'
import theme8 from '../../../../img/dashboard/themes/theme8.webp'

const SliderTheme = ({allThemesSelect}) => {

  const {theme} = useSelector(state => state.menu)



  const settings = {
    dots: false,
    arrows: true,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: '70px',
    className: 'slideThemeMenu',
    swipeToSlide: true,
    infinite: true,
    swipe: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 600,
        settings: 
        {
          slidesToShow: 1,
          centerPadding: '30px',
          arrows: false,
        }
      },
      {
        breakpoint: 1000,
        settings: 
        {
          slidesToShow: 3,
        }
      },
    ]
  }

 
  const templates = [
    {id:0, link: 'https://fooderapp.co/menu/5711111111', title:'Clásico Fooder', img: theme1},
    {id:1, link: 'https://fooderapp.co/menu/5722222222', title:'La Caseta', img: theme2},
    {id:2, link: 'https://fooderapp.co/menu/5733333333', title:'Como en Casa', img: theme3},
    {id:3, link: 'https://fooderapp.co/menu/5744444444', title:'Mi Plato Artesanal', img: theme4},
    {id:4, link: 'https://fooderapp.co/menu/5755555555', title:'Cocina de la Abuela', img: theme5},
    {id:5, link: 'https://fooderapp.co/menu/5766666666', title:'Galería de Platos', img: theme6},
    {id:6, link: 'https://fooderapp.co/menu/5777777777', title:'La Corraleja', img: theme7},
    {id:7, link: 'https://fooderapp.co/menu/5788888888', title:'Cocina Rústica', img: theme8},
  ]

  return (
    <>
      <Slider {...settings}>
        {
          templates.map((el, index) =>(
            <div className="p-2" key={index}>
              <label htmlFor={`chooseTheme-${el.id}`} className={`cardThemeSlide ${theme.a === el.id ? 'selected': ''}`}>
                <h6>{el.title || 'Nombre de plantilla'}</h6>
                <img src={el.img || null} alt="Theme" className='img-fluid'/>
                
                <input
                  type="radio"
                  name='chooseTheme'
                  id={`chooseTheme-${el.id}`}
                  data-theme={el.id}
                  checked={theme.a === el.id}
                  {...allThemesSelect}
                />
                <div className="footerSliderSelect">
                  {
                    theme.a === el.id ? null : (
                      <a rel="noopener noreferrer" href={el.link} target='_blank'>Ver plantilla</a>
                    )
                  }
                  <div className={`btn-line-apple mt-3 ${theme.a === el.id ? 'disabled': ''}`} id={el.id || 0}>
                    {theme.a === el.id  ? 'Seleccionada' : 'Elegir'}
                  </div>
                </div>
              </label>
            </div>
          ))
        }
      </Slider>
    </>
  )
}

export default SliderTheme