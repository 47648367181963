import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import uniqid from 'uniqid';
import { encriptPayment } from "../../../firebase/credentials";
import { setSignaturePlans } from "../../../redux/reducer/admin";

const PaymentModal = () => {
  

  const {
    userID, signatureplans,
    cuota,
    customerList,
    listProducts,
  } = useSelector(s => s.log);

  const {listCategories} = useSelector(s => s.menu)

  const [year, setYear] = useState(true);
  const [overplan, setOverplan] = useState(false);
  const dispatch = useDispatch();
  
  const handleSwitch = () => {
    setYear(!year);
  };
  
  const getSigneture = async (plan, price) => {
    if (!signatureplans[plan]) {
      let id = uniqid();
      let paymentID = `${plan}-${userID}-${id}`;
      let sign = await encriptPayment({
        idSale: paymentID,
        amount: price.toString(),
        currency: "COP",
      });
      let object = { sign, price, paymentID, apiKey: "TQMKPrgT6T4j_GeDEaPKTZ_SPtDrGOxXjhB3R3g5llM" };
      dispatch(setSignaturePlans({ object, plan }));
    }
  };

  useEffect(() => {
    const getAllSigns = async () => {
      await getSigneture('a', 520000);
      await getSigneture('b', 430000);
      await getSigneture('c', 380000);
      await getSigneture('d', 300000);
    };
    getAllSigns();
  }, []);

  useEffect(() => {
    const allPlansExist = ["a", "b", "c", "d"].every(planKey => signatureplans[planKey]);
  
    if (allPlansExist) {
      const loadBoldButton = (planKey) => {
        const planData = signatureplans[planKey];
  
        if (planData && planData.apiKey && planData.sign?.hash) {
          const container = document.getElementById(`bold-button-container-${planKey}`);
          if (container) {
            container.innerHTML = ""; // Limpia el contenedor antes de agregar el script
  
            const script = document.createElement("script");
            script.src = "https://checkout.bold.co/library/boldPaymentButton.js";
            script.setAttribute("data-bold-button", "light-L");
            script.setAttribute("data-order-id", planData.paymentID);
            script.setAttribute("data-currency", "COP");
            script.setAttribute("data-amount", planData.price?.toString());
            script.setAttribute("data-api-key", planData.apiKey);
            script.setAttribute("data-integrity-signature", planData.sign.hash);
            script.setAttribute("data-description", `Fooder ${planKey.toUpperCase()} Plan`);
            
            container.appendChild(script);
          }
        } else {
          console.error(`Error en plan ${planKey}: Datos incompletos.`);
        }
      };
  
      ["a", "b", "c", "d"].forEach(planKey => loadBoldButton(planKey));
    } 
  
  }, [signatureplans]);


  useEffect(()=>{

    if(cuota === 3){
      if(
        listCategories?.length > 15  ||
        listProducts?.length > 150 ||
        customerList?.length > 2500 
      ){
        setOverplan(true)
      }
    }

  },[cuota, listProducts, customerList, listCategories ])
  
  

 

  return (
    <div className="modal fade paymentModalDash" id="paymentModalDash" tabIndex="-1" aria-labelledby="paymentModalDash" aria-hidden="true" data-bs-backdrop="static">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body d-flex justify-content-center align-items-center">
            
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-md-11 px-0 px-md-3">
                  <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-8">
                      <div className="darkPrice">
                        <button
                          className="btnClose"
                          data-bs-dismiss="modal"
                        >
                          Volver
                        </button>

                        <div className="row justify-content-center">
                          <div className="col-md-7 col-lg-6">
                            <div className="info">
                              <div className="top">
                                <h3 className="textPlans d-block d-sm-none mb-4">Planes Fooder</h3>
                                <div className="d-flex align-items-center">

                                  <div className="switch-container me-2 mb-2">
                                    <input
                                      className="toggle-checkbox"
                                      id="toggle-switch"
                                      type="checkbox"
                                      defaultChecked={year}
                                      onChange={handleSwitch}
                                    />
                                    <label className="switch" htmlFor="toggle-switch">
                                      <div className="toggle">
                                        <div className="led"></div>
                                      </div>
                                    </label>
                                  </div>

                                </div>
                                <h4 className="my-2 text-white">Licencia {year ? 'anual': 'semestral'}</h4>
                                {
                                  year && (
                                    <p className="m-0 text-white">Suscríbete al plan anual y ahorra más: <span className="discountLabel">15% o 20%</span> de descuento.</p>
                                  )
                                }
                                <div className="d-block d-sm-none mt-3">
                                  <div className="terms">
                                    Cuando realizas el pago aceptas los <a href="https://fooderapp.co/terms" target="_blank" rel="noreferrer">términos y condiciones</a> y las <a href="https://fooderapp.co/datapolicy" target="_blank" rel="noreferrer">Políticas de tratamiento de datos personales.</a>
                                  </div>
                                </div>
                              </div>
                              <div className="d-none d-sm-block">
                                <h3 className="textPlans">Planes Fooder</h3>
                                <div className="terms">
                                  Cuando realizas el pago aceptas los <a href="https://fooderapp.co/terms" target="_blank" rel="noreferrer">términos y condiciones</a> y las <a href="https://fooderapp.co/datapolicy" target="_blank" rel="noreferrer">Políticas de tratamiento de datos personales.</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 col-lg-6 col-sm-12">
                            <div className="ps-2 h-100">
                              <div className="priceCard">

                                <div className={`bg ${year ? 'd-block': 'd-none'}`}>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="discount">PREMIUM</div>
                                    <span className="discountLabel px-2">20% DTO.</span>
                                  </div>
                                  <div className="prices">
                                    <h3 className="m-0">$520K</h3>
                                    <div>$<span>650</span>K</div>
                                  </div>
                                  <div className="text-center pb-3">
                                    <strong className="text-aqua">Tu eCommerce incluye</strong>
                                  </div>
                                  <div className="item">Recibe pagos por BOLD</div>
                                  <div className="item">Hasta 300 productos inscritos</div>
                                  <div className="item">Hasta 50 categorías</div>
                                  <div className="item">10.000 Clientes en tu agenda</div>
                                  <div className="item">Anuncios Fooder en el menú: <strong className="d-inline-flex ms-1 text-aqua"> NO</strong></div>
                                  <div className="item">Descarga de imagenes ilimitadas</div>
                                  <div className="item">Hasta 800 impresiones diarias</div>
                                  <div className="contentBtn">
                                    <div className="buy">COMPRAR</div>
                                    {signatureplans?.a && <div id="bold-button-container-a"></div>}
                                  </div>
                                </div>


                                <div className={`bg ${year ? 'd-none': 'd-block'}`}>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <div className="discount">PREMIUM</div>
                                  </div>
                                  <div className="prices">
                                    <h3 className="m-0">$380K</h3>
                                  </div>
                                  <div className="text-center pb-3">
                                    <strong className="text-aqua">Tu eCommerce incluye</strong>
                                  </div>
                                  <div className="item">Recibe pagos por BOLD</div>
                                  <div className="item">Hasta 300 productos inscritos</div>
                                  <div className="item">Hasta 50 categorías</div>
                                  <div className="item">10.000 Clientes en tu agenda</div>
                                  <div className="item">Anuncios Fooder en el menú: <strong className="d-inline-flex ms-1 text-aqua"> NO</strong></div>
                                  <div className="item">Descarga de imagenes ilimitadas</div>
                                  <div className="item">Hasta 800 impresiones diarias</div>
                                  <div className="contentBtn">
                                    <div className="buy">COMPRAR</div>
                                    {signatureplans?.c && <div id="bold-button-container-c"></div>}
                                  </div>
                                </div>
                                
                                  
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      !overplan && (
                        <div className="col-sm-12 col-md-7 col-lg-4 mt-4 mt-sm-4 mt-lg-0">
                          <div className="priceCard white">

                            <div className={`bg ${year ? 'd-block': 'd-none'}`}>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="discount">CORPORATIVO</div>
                                <span className="discountLabel px-2">15% DTO.</span>
                              </div>
                              <div className="prices">
                                <h3 className="m-0">$430K</h3>
                                <div>$<span>510</span>K</div>
                              </div>
                              <div className="text-center pb-3">
                                <strong >Tu eCommerce incluye</strong>
                              </div>
                              <div className="item">Recibe pagos por BOLD</div>
                              <div className="item">Hasta 150 productos inscritos</div>
                              <div className="item">Hasta 15 categorías</div>
                              <div className="item">2.500 Clientes en tu agenda</div>
                              <div className="item">Anuncios Fooder en el menú: <strong className="d-inline-flex ms-1"> SI</strong></div>
                              <div className="item">Descarga de imagenes ilimitadas</div>
                              <div className="item">Hasta 300 impresiones diarias</div>
                              <div className="contentBtn">
                                <div className="buy">COMPRAR</div>
                                {signatureplans?.b && <div id="bold-button-container-b"></div>}
                              </div>
                            </div>

                            

                            <div className={`bg ${year ? 'd-none': 'd-block'}`}>
                              <div className="d-flex align-items-center justify-content-center">
                                <div className="discount">CORPORATIVO</div>
                              </div>
                              <div className="prices">
                                <h3 className="m-0">$300K</h3>
                              </div>
                              <div className="text-center pb-3">
                                <strong >Tu eCommerce incluye</strong>
                              </div>
                              <div className="item">Recibe pagos por BOLD</div>
                              <div className="item">Hasta 150 productos inscritos</div>
                              <div className="item">Hasta 15 categorías</div>
                              <div className="item">2.500 Clientes en tu agenda</div>
                              <div className="item">Anuncios Fooder en el menú: <strong className="d-inline-flex ms-1"> SI</strong></div>
                              <div className="item">Descarga de imagenes ilimitadas</div>
                              <div className="item">Hasta 300 impresiones diarias</div>
                              <div className="contentBtn">
                                <div className="buy">COMPRAR</div>
                                {signatureplans?.d && <div id="bold-button-container-d"></div>}
                              </div>
                            </div>
                          </div>

                        </div>
                      )
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
  );
};

export default PaymentModal;