import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "firebase/auth";
import { getFirestore, onSnapshot, doc, getDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, deleteObject, getDownloadURL, uploadString } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getMessaging, getToken } from "firebase/messaging";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};

// Firebase init 
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const messaging = getMessaging(app);

export default app;

//functions//---------------------------------//---------------------------------




export const requestNotificationPermission = async (refNumber) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      return;
    }

    if (!refNumber || refNumber.trim() === "" || refNumber === "null") {
      return;
    }

    
    const token = await getToken(messaging, {
      vapidKey: "BDRDWL2oWbV0FIQh-bobHo3s3fiI9sDVYCtO95F2eK3k-RJAH7fmPBA5DPn5eQDTAUq64JXaJX9w6Emo_uXOVVE"
    });

    if (!token) {
      return;
    }


    
    const tokenRef = doc(db, process.env.REACT_APP_NOTIFICATIONPERMISSION, refNumber);

    
    await setDoc(tokenRef, { fcmToken: token, lastUpdated: new Date().toISOString() }, { merge: true });


  } catch (error) {
    console.error("❌ Error al obtener o guardar el token:", error);
  }
};


function getInfoCollection(userId, collectionName, callback) {
  if(userId && collectionName){
    const docRef = doc(db, collectionName, userId);
    const unsubscribe =  onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        callback(doc.data());
      } else {
        callback(null);
      }
    });
    return unsubscribe;
  }
}


const isUserRegistered = async (email) => {
  try {
    const methods = await fetchSignInMethodsForEmail(auth, email);
    console.log('registrado');
    return methods.length > 0;
  } catch (error) {
    console.log('no registrado');
    console.error(error);
    return false;
  }
}


const undateProductImage = async (userID, imagenBase64, fileName) => {
  const productRef = ref(storage, `product/${userID}/${fileName}`);
  try {
    await uploadString(productRef, imagenBase64, 'data_url');
    const url = await getDownloadURL(productRef);
    return { success: true, message: 'Imagen subida con éxito', url };
  } catch (error) {
    return { success: false, message: 'Error al subir la imagen' };
  }
};


const addProduct = async (userID, datos) => {
  try {
    const userDocRef = doc(db, process.env.REACT_APP_DATAMENU, userID);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      userData.p.push(datos);
      await setDoc(userDocRef, userData);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}


const deleteImageProduct = async (userId, idImage) => {
  try {
    const storageRef = ref(storage, `product/${userId}/${idImage}`);
    await deleteObject(storageRef);
    return true;
  } catch (error) {
    console.error('Error al eliminar la imagen:', error);
    return false;
  }
};


const sendEmailToChangePassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error('Error al enviar el correo electrónico para cambio de contraseña:', error);
    throw error;
  }
}


const addOrderLink = async (country, phone, data) => {
  try {
    const orderId = data.op;
    const ref = doc(db, process.env.REACT_APP_ORDERLINKS, `${country}${phone}`);
    const docData = {
      [orderId]: data 
    };
    await setDoc(ref, docData, { merge: true });
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error
    };
  }
}

const temporalData = async (country, phone, data) => {
  try {
    const orderId = data.op;
    const ref = doc(db, process.env.REACT_APP_CHECKINGORDER, `${country}${phone}`);
    const docData = {
      [orderId]: data 
    };
    await setDoc(ref, docData, { merge: true });
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error
    };
  }
}


const sendAWelcomeEmail = async (data) => {
  const sendWelcomeEmailFunction = httpsCallable(functions, 'sendAWelcomeEmail');
  const {country, email, name, tel} = data
  const obj = {
    name: name || 'Usuario',
    email: email || 'Correo no definido',
    country: country || '57',
    tel: tel || '',
  }
  try {
    const result = await sendWelcomeEmailFunction(obj);
    console.log('Function result:', result.data);
  } catch (error) {
    console.error('Error calling function:', error);
  }
}

const encriptPayment = async (data) => {
  const encript = httpsCallable(functions, 'encriptPayment');
  try {
    const result = await encript(data);
    return {success: true, hash: result.data}
  } catch (error) {
    return {success: false, error}
  }
}

const encriptMenuPayment = async (data) => {
  const encript = httpsCallable(functions, 'encriptMenuPayment');
  try {
    const result = await encript(data);
    return {success: true, hash: result.data}
  } catch (error) {
    return {success: false, error}
  }
}



const mailCredits = async (data) => {
  const sendWelcomeEmailFunction = httpsCallable(functions, 'mailCredits');
  const {credits, email, name} = data
  const obj = {
    name: name || 'Usuario',
    email: email || 'Correo no definido',
    credits: credits || 0,
  }
  try {
    const result = await sendWelcomeEmailFunction(obj);
    console.log('Function result:', result.data);
  } catch (error) {
    console.error('Error calling function:', error);
  }
}

const callEncriptBoldUser = async ({ data, userID }) => {
  try {
    const encriptBoldUserFunction = httpsCallable(functions, "encriptBoldUser");
    const response = await encriptBoldUserFunction({ data, userID });

    if (response.data.success) {
      return true;
    } else {
      console.error("Error en la función encriptBoldUser:", response.data.message);
      return false;
    }
  } catch (error) {
    console.error("Error al llamar la función encriptBoldUser:", error);
    return false;
  }
};


export {
  auth,
  db,
  messaging,
  firebaseConfig,
  isUserRegistered,
  getInfoCollection,
  undateProductImage,
  addProduct,
  deleteImageProduct,
  sendEmailToChangePassword,
  addOrderLink,
  sendAWelcomeEmail,
  encriptPayment,
  mailCredits,
  callEncriptBoldUser,
  encriptMenuPayment,
  temporalData
}