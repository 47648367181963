import { useEffect } from "react";
import { convertAmount } from "../../../tools/format";
import { useSelector } from "react-redux";
import { setCleanOrder, setDataClient, setLoadMenu } from "../../../../redux/reducer/user";
import setOrderInKitchen from "../../../../firebase/update/setOrderInKitchen";
import { setAlmostFinish } from "../../../../redux/reducer/menu";

const ConfirmOrder = ({cleanWhileSentOrder, totalValue , menu, admin, dispatch, setActiveTYC, errorSent, getBackFromConfirmOrder}) => {

  const handleOpenTYC = () => {
    dispatch(setActiveTYC());
  }

  const {paymentData, op} = useSelector(s => s.ordering)
  const {dataClient} = useSelector(s => s.user)


  useEffect(() => {
    if (paymentData && admin?.w?.a && admin?.w?.c) {
  
      let container = document.getElementById('bold-button-container');
      if (!container) {
        container = document.createElement('div');
        container.id = 'bold-button-container';
        document.getElementById('payment-section').appendChild(container);
      } else {
        container.innerHTML = '';  
      }
  
      const script = document.createElement('script');
      script.src = 'https://checkout.bold.co/library/boldPaymentButton.js';
      script.setAttribute('data-bold-button', 'light-L');
      script.setAttribute('data-order-id', paymentData.paymentID);
      script.setAttribute('data-currency', 'COP');
      script.setAttribute('data-amount', paymentData.price.toString());
      script.setAttribute('data-api-key', paymentData.apiKey);
      script.setAttribute('data-integrity-signature', paymentData.sign);
      script.setAttribute('data-render-mode', "embedded");
      script.setAttribute("data-redirection-url", `https://fooderapp.co/menu/${admin.k}${admin.f}/${op}`);
      script.setAttribute("data-description", "Pago de pedido");
  
      container.appendChild(script);
    }
  }, [paymentData, admin, op]);


  const handleCash = async()=>{
    dispatch(setLoadMenu(true))
    const updateOrder = await setOrderInKitchen(admin.k, admin.f, op, 'Efectivo')
    if(updateOrder){
      dispatch(setAlmostFinish(true))
      cleanWhileSentOrder()
    }
    dispatch(setLoadMenu(false))
  }

  useEffect(() => {
    const checkButton = setInterval(() => {
      const boldButton = document.querySelector("bold-payment-button");

      if (boldButton) {
        try {
          const shadowRoot = boldButton.shadowRoot;
          const internalButton = shadowRoot?.querySelector("button");

          if (internalButton) {
            internalButton.addEventListener("click", handleBeforePayment);
            clearInterval(checkButton); 
          }
        } catch (error) {
          console.error("No se pudo acceder al Shadow DOM del botón de Bold:", error);
        }
      }
    }, 500);

    return () => clearInterval(checkButton);
  }, []);

  const handleBeforePayment = () => {
    getBackFromConfirmOrder()
    dispatch(setDataClient({}))
    dispatch(setCleanOrder())
    setTimeout(()=>{
      document.querySelector('#closeMCheckout2').click()
    }, 500)
  };



  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-12 mt-5">
          <div className="row justify-content-center">
            {
              errorSent && (
                <>
                  <div className="col-9 text-center">
                    <small className="errorMenu">No se pudo enviar tu pedido, inténtalo nuevamente</small>
                  </div>
                </>
              )
            }
            <div className="col-md-7 col-11 mt-3">
              <div className="d-flex justify-content-between">
                <span>Subtotal</span>
                <strong>{convertAmount(totalValue)}</strong>
              </div>
              <div className="d-flex justify-content-between">
                <span>Domicilio</span>
                <strong>{!dataClient?.payment?.delivery ? null : convertAmount(parseInt(menu?.r))}
                  {
                    !dataClient?.payment?.delivery && (
                      <span className="text-c1"> (No aplica)</span>
                    )
                  } 
                </strong>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <span>Total a pagar</span>
                {
                  dataClient?.payment?.delivery ? (
                    <strong>{convertAmount(totalValue + parseInt(menu?.r))} </strong>
                  ):
                  (
                    <strong>{convertAmount(totalValue)} </strong>
                  )
                }
              </div>
            </div>
            <div className="col-12 text-center">
              <p className='mt-4'><small>Al enviar tu pedido aceptas los <button className='btn-link' type='button' onClick={handleOpenTYC}>términos y condiciones</button></small></p>
            </div>
            <div className="text-center">
              {
                (admin?.x || admin?.w?.a) ? (
                  <>
                  <div className="row justify-content-center">
                    <div className="col-md-7">
                      <hr className="mt-0 mb-3"/>
                      <h6 className="text-c2">Pago disponible en:</h6>
                    </div>
                    <div className="col-md-9">
                      <div className="row justify-content-center mt-3">
                        {
                          admin?.x && (
                            <div className="col-md-6 col-9">
                              <button
                                className='btn-confirm sendOrderBtn'
                                type='button'
                                onClick={handleCash}
                              >
                                <span>En efectivo</span>
                              </button>
                            </div>
                          )
                        }
                        {
                          (admin?.w?.a && admin?.w?.c) && (
                            <div className="col-md-6 col-9 reserveBold mt-5 mt-md-0">
                              <div className="load" />
                              <div id="payment-section" className="d-flex justify-content-center position-relative" />
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  </>
                ):
                (
                  <h6 className="text-c1 mt-5 mt-md-3">No hay medios de pago disponibles</h6>
                )
              }
            </div>
            
          </div>
        </div>
      </div>
    </>
  )
}

export default ConfirmOrder;