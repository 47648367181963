import React from 'react'
import CommingOrder from './CommingOrder'
import TabKitchen from './TabKitchen'
import ModalConfirmDeleteOrder from './ModalConfirmDeleteOrder'
import ModalDeleteInOrder from './ModalDeleteInOrder'

const KitchenHome = () => {
  return (
    <>
      <section className="py-0 py-md-3 mb-5 mb-md-0">
        <div className="container-fluid">
          <div className="row mb-5 mb-md-0 pb-5 pb-md-0">
            <div className="col-md-4 colOrderPending">
              <CommingOrder />
            </div>
            <div className="col-md-8 px-0 px-md-3">
              <h5 className="text-center text-apple d-block d-md-none mt-4">Producción en Cocina</h5>
              <TabKitchen />
            </div>
          </div>
        </div>
      </section>
      <ModalConfirmDeleteOrder />
      <ModalDeleteInOrder />
      
    </>
  )
}

export default KitchenHome