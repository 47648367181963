import React from 'react'
import {convertAmount} from '../../../tools/format'
import defaultImage from '../../../../img/vector/defaultImage.svg'

const ListOrder = ({title,oneOption,multipleTitle, img, total, counter, comments, handleMinus, handleAdd}) => {

  return (
      <div className="cardOrder">
        <div className="row">
          <div className="col-4">
            <img
              src={img || defaultImage}
              alt={title || 'imagen'}
              className='img-fluid rounded-2'
              onError={(e) => e.target.src = defaultImage}
            />

          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-12">
                <p className='m-0'>{title || 'NA'}</p>
                <p className='my-1'><small className='d-block'>{oneOption || ''}</small></p>
                <p className='mb-0'><small className='d-block'>{multipleTitle}</small></p>
                <p className="mb-2"><em><small>{comments || ''}</small></em></p>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-6 align-self-center text-center">
                <strong>{convertAmount(total * counter)}</strong>
              </div>
              <div className="col-6">
                <div className="counterProduct">
                  <button onClick={handleMinus}  type='button' className='minus'>-</button>
                  <span>{counter}</span>
                  <button onClick={handleAdd}  type='button' className='plus'>+</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ListOrder