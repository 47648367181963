import React, { useEffect, useState, useRef, useCallback } from 'react';
import { productsTest } from '../../dummi/source-products';
import firstCat from '../../dummi/source-categories';
import { convertAmount } from '../../../tools/format';
import { useDispatch, useSelector } from 'react-redux';
import { setProductSelected, setSearching } from '../../../../redux/reducer/user';
import { setOp } from '../../../../redux/reducer/ordering';

const ITEMS_PER_LOAD = 20; 

const ListProducts = ({ refContent, twoColumns = true }) => {
  const { menu, searching, favoriteStorage } = useSelector((state) => state.user);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState([]); 
  const observerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (menu) {
      if (menu?.p?.length === 0) {
        setDataCategory(firstCat);
        setDataProduct(productsTest);
      } else {
        setDataCategory(menu?.n);
        setDataProduct(menu?.p);
      }
    }
  }, [menu]);

  
  useEffect(() => {
    setVisibleProducts(dataProduct.slice(0, ITEMS_PER_LOAD));
  }, [dataProduct]);

  
  const loadMoreProducts = useCallback(() => {
    setVisibleProducts((prev) => {
      const nextProducts = dataProduct.slice(prev.length, prev.length + ITEMS_PER_LOAD);
      return [...prev, ...nextProducts];
    });
  }, [dataProduct]);

  
  useEffect(() => {
    if (!dataProduct.length) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreProducts();
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [dataProduct, loadMoreProducts]);


  const filteredData = dataProduct ? dataProduct.filter(item =>
    item?.e?.toLowerCase().includes(searching.toLowerCase()) ||
    item?.g?.toLowerCase().includes(searching.toLowerCase()) ||
    item?.h?.toLowerCase().includes(searching.toLowerCase())
  ): []

  const showModalProduct = (id) => {
    const reference = dataProduct.find((el) => el?.c === id);
    dispatch(setProductSelected(reference));
    dispatch(setOp())
  };

  const cleanFilter = () => dispatch(setSearching(''));

  return (
    <div className="container-fluid productsTexture">
      <div className="row justify-content-center">
        <div className="col-11 pb-4 pb-md-5 px-0" ref={refContent}>
          {searching?.length === 0 ? (
            <>
              {dataCategory?.map((cat) => {
                const matchingProducts = visibleProducts.filter((product) => product?.g === cat?.name);
                const activeProducts = matchingProducts.filter((product) => !product?.b && product?.a);

                return (
                  <div className="row refPrintProducts" key={cat?.name} data-ref={cat?.name}>
                    <div className="col-12">
                      <div className="headCategory">
                        <h6 className="m-0">{cat?.name}</h6>
                        <span>{activeProducts?.length} {activeProducts?.length === 1 ? 'Producto' : 'Productos'}</span>
                        <div />
                      </div>
                    </div>
                    {matchingProducts?.map((el) => {

                      let isFavorite = false
                      let res = favoriteStorage.findIndex(item => item === el?.c)
                      if(res === -1){
                        isFavorite = false
                      }else{
                        isFavorite = true
                      }

                      if (el?.a && !el?.b) {
                        return (
                          <div className={`${twoColumns ? 'col-md-6 mb-3' : 'col-md-4 col-6 mb-3'}`} key={el?.c}>
                            <button
                              className="productList"
                              onClick={() => showModalProduct(el?.c)}
                              data-bs-toggle="modal"
                              data-bs-target="#showMProduct"
                            >
                              <div className="img">
                                <img src={el?.d} alt={el?.e} loading="lazy" />
                              </div>
                              <div className="content">
                                <div className="top">
                                  <h6 className="text-truncate">{el?.e}</h6>
                                  <p>{el?.h}</p>
                                  <div className="prices">
                                    {el?.f?.[1] === null ? (
                                      <strong className="after">{convertAmount(el?.f?.[0])}</strong>
                                    ) : (
                                      <>
                                        <span className="before">Antes <span>{convertAmount(el?.f?.[0])}</span></span>
                                        <strong className="after">{convertAmount(el?.f?.[1])}</strong>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="bottom">
                                  <div className="add">
                                    <div className='d-flex align-items-center'>
                                      {
                                        isFavorite && (
                                          <>
                                            <div className='favorite'>
                                              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225 225" fill={`${menu?.g?.[0]}`}>
                                                <path d="m114,198.53c-23.76-22.9-47.56-45.75-71.24-68.72-5.85-5.68-11.89-11.32-16.82-17.76-13.53-17.69-15.79-37.17-6.01-57.2,9.65-19.76,26.3-29.67,48.15-29.85,13.44-.11,25.38,4.89,35.18,14.29,3.1,2.98,5.79,6.39,9.36,10.39,3.79-4.18,6.52-7.82,9.87-10.78,15.51-13.71,33.4-17.73,52.9-10.86,19.47,6.86,31.09,21.19,34.75,41.5,3.39,18.77-2.5,34.83-15.71,48.27-26.29,26.74-52.67,53.39-79.01,80.07-.81.83-1.68,1.6-2.53,2.39.37-.58.74-1.15,1.11-1.73Zm-.34-19.14c-.52.39.05.06.5-.39,24.12-24.42,48.31-48.78,72.31-73.32,6.76-6.91,10.11-15.61,10.58-25.23.81-16.45-8.86-31.95-23.69-38.39-14.89-6.46-31.1-3.14-43.16,8.78-5.89,5.82-11.86,11.57-17.2,16.78-5.93-5.6-12.03-11.27-18.03-17.04-9.18-8.82-20.04-13.19-32.73-11.3-15.7,2.34-26.58,11.37-31.74,26.26-5.37,15.51-1.81,29.59,9.55,41.42,10.27,10.69,20.69,21.25,31.28,31.63,13.91,13.64,28.05,27.05,42.33,40.8Z"/>
                                              </svg>
                                            </div>
                                          </>
                                        )
                                      }
                                      {
                                        el?.k && (
                                          <svg id="Capa_1" className='icon' data-name="Capa 1" fill={`${menu?.g?.[3]}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                                            <path d="m32.62,11.06c-.53-.05-.99-.14-1.45-.12-.29.01-.61.15-.87.32-.64.42-1.16.96-1.52,1.64-.1.2-.13.38-.04.58.15.32.16.65.13.99,0,.07.01.18.06.22.63.53.75,1.22.67,1.98-.1.91-.52,1.7-.99,2.47-1.36,2.16-3.14,3.91-5.2,5.39-1.69,1.21-3.49,2.21-5.49,2.78-3.51,1-6.85.5-10.03-1.21-2.46-1.32-3.99-3.4-4.84-6.02-.13-.4,0-.76.32-.98.32-.22.69-.2,1.03.06.98.75,2,1.45,3.17,1.87,2.72.96,5.28.61,7.67-.99.75-.5,1.28-1.21,1.71-1.98.48-.85.92-1.73,1.38-2.6.55-1.04,1.18-2.04,2.05-2.85.69-.64,1.46-1.14,2.35-1.44.91-.31,1.82-.34,2.67.19.04.02.09.04.13.04.34.02.69.02,1.03.05.16.01.24-.04.32-.17.57-.96,1.3-1.79,2.24-2.42.75-.5,1.58-.77,2.49-.67,1.02.11,1.58.92,1.33,1.94-.08.33-.22.65-.32.92ZM4.41,20.58s0,.01,0,.03c.01.04.02.07.04.11.84,1.93,2.12,3.45,4,4.44,3.6,1.9,7.27,2.12,11,.42,2.52-1.14,4.69-2.77,6.56-4.81.75-.82,1.42-1.72,1.97-2.69.28-.5.51-1.03.56-1.61.03-.32,0-.62-.23-.87-.46.24-.72.3-1.15.22-.74-.15-1.42-.44-1.93-1.01-.68-.75-1.1-1.59-.67-2.65-.31-.12-.64-.13-.96-.07-.89.16-1.63.62-2.31,1.2-.82.71-1.4,1.61-1.9,2.55-.52.97-1.01,1.95-1.56,2.9-.51.9-1.17,1.7-2.05,2.27-3.11,1.99-6.34,2.19-9.66.57-.59-.29-1.13-.66-1.7-.99Zm27.47-10.8c.04-.32-.07-.47-.4-.5-.09,0-.17,0-.26,0-.79.03-1.44.39-2.02.9-.58.51-1.08,1.1-1.45,1.78-.25.46-.61.66-1.12.59-.23-.03-.47-.05-.7-.06-.26-.01-.48.2-.46.46,0,.14.05.28.1.41.28.73.85,1.12,1.57,1.33.15.04.43.07.47,0,.11-.19.13-.43.17-.66,0-.05-.03-.1-.05-.15-.2-.51-.16-1.01.09-1.5.48-.9,1.17-1.59,2.01-2.13.24-.16.54-.28.82-.33.39-.08.8-.09,1.23-.13Z"/>
                                            <path d="m10.7,23.98c2.54-.2,4.78-1.13,6.61-2.95.84-.84,1.4-1.88,1.93-2.94.39-.77.75-1.55,1.18-2.3.46-.82,1.06-1.54,1.78-2.15.12-.1.28-.17.44-.19.22-.03.43.14.5.35.08.23.04.45-.15.6-1.24.93-2.04,2.19-2.78,3.52-.47.83-.99,1.64-1.55,2.41-1.12,1.55-2.69,2.46-4.46,3.09-1.04.37-2.1.58-3.2.59-.06,0-.13,0-.19,0-.05,0-.09-.02-.11-.02Z"/>
                                          </svg>
                                        )
                                      }
                                    </div>
                                    <span>Agregar</span>
                                  </div>
                                </div>
                              </div>
                            </button>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12 my-4 pt-2">
                  <div className="d-block d-md-flex align-items-center">
                    <h5 className="mb-3 mb-md-0 text-results">Resultados de tu búsqueda </h5>
                    <div className="searchBread ms-0 ms-md-3">
                      <span>{searching}</span>
                      <button type="button" onClick={cleanFilter} />
                    </div>
                  </div>
                </div>
                {
                    filteredData?.length === 0 ?(
                      <>
                        <div className='noFound'>No hay coincidencias con tu criterio de búsqueda. <button type='button' onClick={cleanFilter}>Intenta filtrar con otras palabras clave.</button></div>
                      </>
                    ):
                    (
                      <>
                        {    
                          filteredData?.map(el => {
                            let isFavorite = false
                            let res = favoriteStorage?.findIndex(item => item === el?.c)
                            if(res === -1){
                              isFavorite = false
                            }else{
                              isFavorite = true
                            }
                            return (
                              <div className={`${twoColumns ? 'col-md-6 mb-3': 'col-md-4  col-6  mb-3'}`} key={el?.c}>
                                <button
                                  className="productList"
                                  onClick={()=> showModalProduct(el?.c)}
                                  data-bs-toggle="modal" data-bs-target="#showMProduct"
                                >
                                  <div className="img">
                                    <img src={el?.d} alt={el?.e} />
                                    <div className="label">{el?.g}</div>
                                  </div>
                                  <div className="content">
                                    <div className="top">
                                      <h6 className="text-truncate">{el?.e}</h6>
                                      <p>{el?.h}</p>
                                      <div className="prices">
                                        {
                                          el?.f?.[1] === null ? (
                                            <>
                                              <strong className="after">{convertAmount(el?.f?.[0])}</strong>
                                            </>
                                          ):
                                          (
                                            <>
                                              <span className="before">Antes <span>{convertAmount(el?.f?.[0])}</span></span>
                                              <strong className="after">{convertAmount(el?.f?.[1])}</strong>
                                            </>
                                          )
                                        }
                                      </div>
                                    </div>
                                    <div className="bottom">
                                      <div className="add">
                                        <div className='d-flex align-items-center'>
                                        {
                                          isFavorite && (
                                            <>
                                              <div className='favorite'>
                                                <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225 225" fill={`${menu?.g?.[0]}`}>
                                                  <path d="m114,198.53c-23.76-22.9-47.56-45.75-71.24-68.72-5.85-5.68-11.89-11.32-16.82-17.76-13.53-17.69-15.79-37.17-6.01-57.2,9.65-19.76,26.3-29.67,48.15-29.85,13.44-.11,25.38,4.89,35.18,14.29,3.1,2.98,5.79,6.39,9.36,10.39,3.79-4.18,6.52-7.82,9.87-10.78,15.51-13.71,33.4-17.73,52.9-10.86,19.47,6.86,31.09,21.19,34.75,41.5,3.39,18.77-2.5,34.83-15.71,48.27-26.29,26.74-52.67,53.39-79.01,80.07-.81.83-1.68,1.6-2.53,2.39.37-.58.74-1.15,1.11-1.73Zm-.34-19.14c-.52.39.05.06.5-.39,24.12-24.42,48.31-48.78,72.31-73.32,6.76-6.91,10.11-15.61,10.58-25.23.81-16.45-8.86-31.95-23.69-38.39-14.89-6.46-31.1-3.14-43.16,8.78-5.89,5.82-11.86,11.57-17.2,16.78-5.93-5.6-12.03-11.27-18.03-17.04-9.18-8.82-20.04-13.19-32.73-11.3-15.7,2.34-26.58,11.37-31.74,26.26-5.37,15.51-1.81,29.59,9.55,41.42,10.27,10.69,20.69,21.25,31.28,31.63,13.91,13.64,28.05,27.05,42.33,40.8Z"/>
                                                </svg>
                                              </div>
                                            </>
                                          )
                                        }
                                        {
                                          el?.k && (
                                            <svg id="Capa_1" className='icon' data-name="Capa 1" fill={`${menu?.g?.[3]}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                                              <path d="m32.62,11.06c-.53-.05-.99-.14-1.45-.12-.29.01-.61.15-.87.32-.64.42-1.16.96-1.52,1.64-.1.2-.13.38-.04.58.15.32.16.65.13.99,0,.07.01.18.06.22.63.53.75,1.22.67,1.98-.1.91-.52,1.7-.99,2.47-1.36,2.16-3.14,3.91-5.2,5.39-1.69,1.21-3.49,2.21-5.49,2.78-3.51,1-6.85.5-10.03-1.21-2.46-1.32-3.99-3.4-4.84-6.02-.13-.4,0-.76.32-.98.32-.22.69-.2,1.03.06.98.75,2,1.45,3.17,1.87,2.72.96,5.28.61,7.67-.99.75-.5,1.28-1.21,1.71-1.98.48-.85.92-1.73,1.38-2.6.55-1.04,1.18-2.04,2.05-2.85.69-.64,1.46-1.14,2.35-1.44.91-.31,1.82-.34,2.67.19.04.02.09.04.13.04.34.02.69.02,1.03.05.16.01.24-.04.32-.17.57-.96,1.3-1.79,2.24-2.42.75-.5,1.58-.77,2.49-.67,1.02.11,1.58.92,1.33,1.94-.08.33-.22.65-.32.92ZM4.41,20.58s0,.01,0,.03c.01.04.02.07.04.11.84,1.93,2.12,3.45,4,4.44,3.6,1.9,7.27,2.12,11,.42,2.52-1.14,4.69-2.77,6.56-4.81.75-.82,1.42-1.72,1.97-2.69.28-.5.51-1.03.56-1.61.03-.32,0-.62-.23-.87-.46.24-.72.3-1.15.22-.74-.15-1.42-.44-1.93-1.01-.68-.75-1.1-1.59-.67-2.65-.31-.12-.64-.13-.96-.07-.89.16-1.63.62-2.31,1.2-.82.71-1.4,1.61-1.9,2.55-.52.97-1.01,1.95-1.56,2.9-.51.9-1.17,1.7-2.05,2.27-3.11,1.99-6.34,2.19-9.66.57-.59-.29-1.13-.66-1.7-.99Zm27.47-10.8c.04-.32-.07-.47-.4-.5-.09,0-.17,0-.26,0-.79.03-1.44.39-2.02.9-.58.51-1.08,1.1-1.45,1.78-.25.46-.61.66-1.12.59-.23-.03-.47-.05-.7-.06-.26-.01-.48.2-.46.46,0,.14.05.28.1.41.28.73.85,1.12,1.57,1.33.15.04.43.07.47,0,.11-.19.13-.43.17-.66,0-.05-.03-.1-.05-.15-.2-.51-.16-1.01.09-1.5.48-.9,1.17-1.59,2.01-2.13.24-.16.54-.28.82-.33.39-.08.8-.09,1.23-.13Z"/>
                                              <path d="m10.7,23.98c2.54-.2,4.78-1.13,6.61-2.95.84-.84,1.4-1.88,1.93-2.94.39-.77.75-1.55,1.18-2.3.46-.82,1.06-1.54,1.78-2.15.12-.1.28-.17.44-.19.22-.03.43.14.5.35.08.23.04.45-.15.6-1.24.93-2.04,2.19-2.78,3.52-.47.83-.99,1.64-1.55,2.41-1.12,1.55-2.69,2.46-4.46,3.09-1.04.37-2.1.58-3.2.59-.06,0-.13,0-.19,0-.05,0-.09-.02-.11-.02Z"/>
                                            </svg>
                                          )
                                        }
                                        </div>
                                        <span>Agregar</span>
                                      </div>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )
                          })}
                      </>
                    )
                  }
              </div>
            </>
          )}
          
          <div ref={observerRef} style={{ height: '50px' }} />
        </div>
      </div>
    </div>
  );
};

export default ListProducts;
