import React from 'react'
import Bold from './bold/Bold'
import banner from '../../../../img/dashboard/photo/bold.webp'
import bannerSm from '../../../../img/dashboard/photo/bold_sm.webp'
import Cash from './cash/Cash'


const Payments = () => {
  return (
    <section className="py-3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-12">
            <img src={banner} alt="" className='img-fluid rounded-4 d-none d-md-block'/>
            <img src={bannerSm} alt="" className='img-fluid rounded-4 d-block d-md-none'/>
          </div>
          <div className="col-md-10">
            <Cash />
          </div>
          <div className="col-md-10">
            <Bold />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Payments
