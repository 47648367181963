import { QRCodeCanvas } from 'qrcode.react'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import totalCalculate from '../../../tools/totalCalculate'
import { convertAmount } from '../../../tools/format'
import totalOrder from '../../../tools/totalOrder'
import { setInfoToPrint } from '../../../../redux/reducer/ordering'
import { useReactToPrint } from 'react-to-print'



const PrintTicket = () => {

  const { phone, businessName, address, email, logoMenu, ticket, indicative } = useSelector(s => s.log)
  const {infoToPrint} = useSelector(s => s.ordering)
  const dispatch = useDispatch()
  const contentPrint = useRef(null)

  
  const handleClose = ()=> dispatch(setInfoToPrint(null))

  // define size print document
  const resizeDoc = (width, height = 22) =>{
    const styleSheet = document.createElement('style')
    const cssRule = `@page {size: ${width}cm ${height}cm}`
    styleSheet.appendChild(document.createTextNode(cssRule))
    document.head.appendChild(styleSheet)
  }

  const openToPrint = useReactToPrint({
    content: () => contentPrint.current,
    documentTitle: `${infoToPrint?.client?.name} - OP: ${infoToPrint?.op?.toUpperCase()}`,
    onAfterPrint: ()=>{
      const styleSheet = document.querySelector('style')
      if (styleSheet) {
        styleSheet.parentNode.removeChild(styleSheet)
      }
      document.querySelector('#closePrintModal').click()
      dispatch(setInfoToPrint(null))
    }
  })

  const handlePrint = ()=>{
    const newHight = contentPrint.current.offsetHeight
    resizeDoc(8, newHight / 37.8)
    openToPrint()
  }



  return (
    <div className="modal fade modalDashboard modalPrintOrder" id="modalPrintOrder" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalPrintOrderLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <div>
              <button
                type='button'
                className='btn-line-apple printBtn'
                onClick={handlePrint}
              >Imprimir</button>

              <button
                type="button"
                className="btn-green-dark w-100 p-relative"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
                id='closePrintModal'
              >Cerrar</button>

            </div>

            </div>
          <div className="modal-body">
            <div className='d-flex justify-content-center'>
              <div className="printArea bg-white py-5" ref={contentPrint}>
                <div className="py-4">
                  <div className="text-center">
                    {ticket?.[6] && (
                      <>
                        {logoMenu && logoMenu.length > 0 && (
                          <>
                            <div className="d-flex justify-content-center">
                              <img src={logoMenu} alt="logo" className="logoOrder" />
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <strong className="d-block text-uppercase ">{businessName}</strong>
                    {ticket?.[0]?.length > 0 && (
                      <strong className="d-block text-uppercase mb-3 text-legal">
                        NIT.: {ticket[0]}
                      </strong>
                    )}
                  </div>
                  <hr />
                  <strong className="d-block text-center">CLIENTE</strong>
                  <div className="row">
                    <div className="col-12">
                      <p> Cliente: <strong className='text-capitalize'>{infoToPrint?.client?.name}</strong></p>
                      
                    </div>
                    <div className="col-12">
                      <p>Tel/Cel.: {infoToPrint?.client?.phone}</p>
                    </div>
                    <div className="col-12">
                      <p>Método de pago: {infoToPrint?.payment?.method === 'cash' ? 'Efectivo': infoToPrint?.payment?.method}</p>
                    </div>
                    <div className="col-12">
                      <p>Forma de entrega: {infoToPrint?.payment?.delivery ? 'Domicilio' : 'Recoger en punto'}</p>
                    </div>
                    <div className="col-12">
                      <p className='text-uppercase'>OP: {infoToPrint?.op}</p>
                    </div>
                    <div className="col-12">
                      <p>Expedido: {infoToPrint?.client?.date}</p>
                    </div>
                  </div>
                  <hr />
                  <strong className="d-block text-center mb-2">PRODUCTOS</strong>
                  <div className="row">
                    <div className="col-2 text-center">
                      <strong className="d-block mb-2">Cant.</strong>
                    </div>
                    <div className="col-6">
                      <strong className="d-block mb-2">Descripción</strong>
                    </div>
                    <div className="col-4 text-end">
                      <strong className="d-block mb-2">Valor</strong>
                    </div>

                    <div className="d-flex justify-content-center">
                      <table>
                        <tbody>
                          {
                            infoToPrint?.order?.map(el => (
                              <tr key={el?.id}>
                                <td>{el?.count}</td>
                                <td>
                                  <p className='d-inline-flex'>{el?.title} </p>
                                  {
                                    el?.oneOption?.length !== 0 && (
                                      <p>{el?.oneOption}</p>
                                    )
                                  }
                                  {
                                    el?.multipleTitle?.length !== 0 && (
                                      <p>{el?.multipleTitle}</p>
                                    )
                                  }
                                </td>
                                <td>{convertAmount(el?.price * el?.count)}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                  <strong className="d-block text-center mb-3">
                    RESUMEN DE COSTOS
                  </strong>
                  <div className="row justify-content-end">
                    <div className="col-md-7 col-6 text-end px-0">
                      <p className="mb-1">Subtotal</p>
                      <p className="mb-1">Valor domicilio</p>
                      <p className="mb-1">Total descuento</p>

                      <p>Total a pagar</p>
                    </div>
                    {
                      infoToPrint && (
                        <>    
                          <div className="col-md-5 col-6 text-end">
                            <p className="mb-1">{convertAmount(totalOrder(infoToPrint))}</p>
                            <p className="mb-1">{convertAmount(infoToPrint?.payment?.deliveryPrice)}</p>
                            <p className="mb-1">{convertAmount(infoToPrint?.payment?.discount)}</p>
                            <p>
                              <strong>{convertAmount(totalCalculate(infoToPrint))}</strong>
                            </p>
                          </div>
                        </>
                      )
                    }
                  </div>
                  <hr />
                  {ticket?.[2]?.length > 0 && (
                    <>
                      <small className="text-legal" style={{ textAlign: "justify" }}>
                        {ticket[2]}
                      </small>
                      <hr />
                    </>
                  )}
                  {ticket && (
                    <>
                      <p className="text-center">
                        {ticket?.[3] && (
                          <>
                            Dir.:{address[0]} {address[1]} # {address[2]} - {address[3]}{" "}
                            | {address[4]}, {address[5]}
                          </>
                        )}{" "}
                        {ticket?.[4] && (
                          <>
                            <span>Tel.: {phone}</span>
                          </>
                        )}
                      </p>
                    </>
                  )}
                  {ticket?.[5] && (
                    <>
                      <p className="text-center">Email: {email}</p>
                      <hr />
                    </>
                  )}

                  <p className="text-center mt-2">
                    Desarrollado por Fooder - Digitaliza tus comidas +57 312 559 9832
                  </p>
                  <hr />
                  {ticket?.[7] && (
                    <>
                      <div className="d-flex justify-content-center mb-2 mt-3">
                        <QRCodeCanvas
                          value={`https://fooderapp.co/menu/${indicative}${phone}`}
                          className="qrCode mb-1"
                          size={130}
                        />
                      </div>
                      <small className="d-block text-center text-legal">
                        Para futuras compras visita nuestro sitio web
                      </small>
                    </>
                  )}
                  {ticket?.[1]?.length > 0 && (
                    <>
                      <strong className="d-block text-center mt-2">{ticket[1]}</strong>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrintTicket