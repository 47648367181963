import React, { useEffect, useRef } from 'react'
import '../../../../sass/themes/t1.sass'
import Header from './Header'
import Categories from './Categories'
import Aside from './Aside'
import MainSlider from './MainSlider'
import ListProducts from './ListProducts'
import FooderAd from './FooderAd'
import ModalSelectedProduct from './ModalSelectedProduct'
import FooterMenu from './FooterMenu'
import PlegableMenu from './PlegableMenu'
import AboutInfo from './AboutInfo'
import ModalConfirmDeleted from './ModalConfirmDeleted'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveMenu, setActiveOrder, setCleanOrder, setCloseAboutCompany, setShowOrderBold } from '../../../../redux/reducer/user'
import Checkout from './Checkout'
import AdDesk from './AdDesk'
import { useSwipeable } from 'react-swipeable'
import BtnOrderPending from './BtnOrderPending'
import AlmostFinised from '../transversaltheme/AlmostFinised'
import Closed from './Closed'
import { useParams } from 'react-router-dom'
import OrderLinkBold from './OrderLinkBold'
import { setMustWspNotification } from '../../../../redux/reducer/menu'
import SetBoldNotification from '../transversaltheme/SetBoldNotification'

const MainFreeTheme = ({bgMenu = null, editCategory = null, twoColumns}) => {

  const cat = useRef(null)
  const refContent = useRef(null)
  const dispatch = useDispatch()
  const {activeOrder, orderSent, admin, menu} = useSelector(state => state.user)
  const {mustWspNotification} = useSelector(state => state.menu)

  const param = useParams()
    

  useEffect(()=>{
    if(param){
      if(param.op){
        dispatch(setShowOrderBold(param.op))
        dispatch(setCleanOrder())
        dispatch(setMustWspNotification(true))
      }
    }
  },[param, dispatch])
  

  useEffect(() => {
    dispatch(setCloseAboutCompany())
  }, [dispatch])

  const interactionsSlide = useSwipeable({
    onSwipedRight: (e) => {
      if(!e.event.target.getAttribute('data-enableswipe')){
        dispatch(setActiveMenu()) 
      }
    },
    onSwipedLeft: (e) => {
      if(!e.event.target.getAttribute('data-enableswipe')){
        dispatch(setActiveOrder())
      }
    },
  })

  const interactionClose = useSwipeable({
    onSwipedRight: () => {
      dispatch(setActiveOrder())
    }
  })

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);




  return (
    <>
    {
      (!menu?.a || admin?.c <1 || admin?.g === 0) ? <Closed /> :
      (
        <>
          <section className='wrappeContent'>
            {bgMenu}
            <main 
              {...interactionsSlide}
              className='p-relative'
            >
              <Header />
              <Categories cat={cat} editCategory={editCategory}/>
              {orderSent?.length > 0 && <BtnOrderPending />}
              <OrderLinkBold />
              <MainSlider />
              <ListProducts refContent={refContent} twoColumns={twoColumns}/>
              {admin?.u === 3 ? null : <AdDesk />}
              {mustWspNotification && <SetBoldNotification />}
              <FooterMenu />
              <FooderAd />
            </main>
            <aside
              className={`${activeOrder ? 'active': ''}`}
              {...interactionClose}
            >
              <Aside />
            </aside>
          </section>
          <PlegableMenu />
          <AboutInfo />
          <ModalConfirmDeleted />
          <ModalSelectedProduct />
          <Checkout />
          <AlmostFinised />
        </>
      )
    }
    </>
  )
}

export default MainFreeTheme