import { doc, setDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/credentials";

const userRegister = async(data, date) => {
  
  const concatValue = data.country + data.telRegister;

  try {
    const myCustomersRef = collection(db, process.env.REACT_APP_GLOBALINFO);

    const queryByConcat = query(myCustomersRef, where("c", "==", concatValue));
    const querySnapshotByConcat = await getDocs(queryByConcat);

    
    const queryByEmail = query(myCustomersRef, where("e", "==", data.emailRegister));
    const querySnapshotByEmail = await getDocs(queryByEmail);

    
    if (!querySnapshotByConcat.empty || !querySnapshotByEmail.empty) {
      return false;
    }

    
    await setDoc(doc(db, "register", data.telRegister), {
      name: data.fullNameRegister,
      country: data.country,
      tel: data.telRegister,
      email: data.emailRegister,
      date: date,
      solve: false,
    });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export default userRegister;
