import { doc, updateDoc } from "firebase/firestore"
import { db } from "../credentials"


const updateTemplate = async(userID, data) =>{

  const ref = doc(db, process.env.REACT_APP_DATAMENU, userID)

  try {
    await updateDoc(ref, {
      e: data.e,
      f: data.f,
      g: data.g,
      h: data.h,
      i: data.i,
      j: data.j,
      k: data.k,
      l: data.l,
      m: data.m,
      q: data.q
    })
    return true
  } catch (error) {
    return false
  }

}

export default updateTemplate