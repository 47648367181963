import { db } from "../../../../../firebase/credentials"
import { updateDoc, doc } from "firebase/firestore"

const dismissStep = async(userId, toStep)=>{

  const ref = doc(db, process.env.REACT_APP_DATAUSER, userId)

  try {
    await updateDoc(ref, {
      d: toStep
    })
    return true
  } catch (error) {
    return false
  }

}

export default dismissStep