import React from 'react'
import broke from '../../img/dashboard/broke_plate.svg'

const Steps = ({step, isDelivery}) => {


  return (
    <>
      <div className="row justify-content-center mb-4">
        <div className="col-12 text-center">
          {step === 5 ? null : <h5 className='mb-4 mt-0 mt-md-4'>Estado de tu pedido</h5>}
        </div>
        <div className="col-md-10">
          {
            step === 5 ? (
              <>
                <div className="errorOrder">
                  <img src={broke} alt="broke plate" />
                  <div>
                    <h6>¡Tu pedido ha sido cancelado!</h6>
                    <p className='m-0'>Lamentamos informarte que tu pedido ha sido rechazado debido a: pago no confirmado, producto no disponible o servicio a domicilio no disponible. ¡Gracias por tu comprensión y esperamos poder servirte en el futuro!</p>
                  </div>
                </div>
              </>
            ):
            (
              <>    
                <div className="orderState">
                  <div>
                    <p className={`${step === 0 ? 'current': 'checked'}`}>Por aceptar</p>
                    <span className={`${step === 0 ? 'current': 'checked'}`} />
                  </div>
                  <div>
                    {step === 0 && (<>
                      <p className='disabled'>En preparación</p>
                      <span className='disabled'/></>)
                    }
                    {step === 1 && (<>
                      <p className='current'>Preparando</p>
                      <span className='current'/></>)
                    }
                    {(step === 2 || step === 3 || step === 4) && (<>
                      <p className='checked'>Preparado</p>
                      <span className='checked'/></>)
                    }
                  </div>
                  <div>
                    {(step === 0 || step === 1) && (<>
                      <p className='disabled'>Terminando</p>
                      <span className='disabled'/></>)
                    }
                    {step === 2 && (<>
                      <p className='current'>Terminando</p>
                      <span className='current'/></>)
                    }
                    {(step === 3 || step === 4) && (<>
                      <p className='checked'>Terminado</p>
                      <span className='checked'/></>)
                    }
      
                  </div>
                  <div>
                    {(step === 0 || step === 1 || step === 2) && (<>
                      <p className='disabled'>Entregando</p>
                      <span className='disabled'/></>)
                    }
                    {step === 3 && (<>
                      <p className='current'>Entregando</p>
                      <span className='current'/></>)
                    }
                    {(step === 4) && (<>
                      <p className='checked'>Entregado</p>
                      <span className='checked'/></>)
                    }
                  </div>

                  <div className="line">
                    <div className={`${(step === 1 || step === 2 || step === 3 || step === 4) ? 'checked': 'disabled'}`} />
                    <div className={`${(step === 2 || step === 3  || step === 4) ? 'checked': 'disabled'}`} />
                    <div className={`${(step === 3 || step === 4) ? 'checked': 'disabled'}`} />
                  </div>
                </div>
                <div className="text-center mt-5">
                  {step === 0 && (
                    <p>Estamos verificando el estado de tu pago para comenzar a preparar el pedido, esto puede tardar unos minutos.</p>
                  )}
                  {step === 1 && (
                    <p>Tu pedido ya se encuentra en preparación. El tiempo dependerá del tipo de producto como la cantidad que solicitaste en tu orden general, agradecemos tu amable espera.</p>
                  )}
                  {step === 2 && (
                    <p>¡Tu producto ya se encuentra preparado!. Estamos ultimando detalles para la entrega de tu orden.</p>
                  )}
                  {step === 3 && (
                    <>
                      {
                        isDelivery ? (<>
                          <p>¡Tu orden ya se encuentra en camino!. Te recomendamos revisar si todo se encuentra en orden, <strong>confirma con el domiciliario que tu pago ha sido efectuado en su totalidad.</strong></p>
                        </>)
                        :
                        (<>
                          <p>¡Hey, te estamos esperando en el restaurante!. Te recomendamos revisar si todo se encuentra en orden, <strong>confirma en el punto de venta que tu pago ha sido efectuado en su totalidad.</strong></p>
                        </>)
                      }
                    </>
                  )}
                  {step === 4 && (
                    <p>¡Ya ha sido entregada tu orden!, esperamos que disfrutes tu comida. <strong>¡Gracias por preferirnos!</strong></p>
                  )}
                </div>
              </>
            )
          }
        </div>
      </div>
    </>
  )
}

export default Steps