import React, { useState, useEffect } from 'react';
import InfoGreen from '../InfoGreen';
import { useDispatch } from 'react-redux';
import { setOrderReports } from '../../../../redux/reducer/interactions';

const Table = ({ data }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const dispatch = useDispatch()

  
  const convertToDate = (dateString) => {
    if (!dateString) return new Date(0); 

    
    const [day, monthStr, yearTime] = dateString.split('/');
    const [year, time, period] = yearTime.split(' ');

    
    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    const month = monthNames.indexOf(monthStr);

    
    let [hours, minutes] = time.split(':').map(Number);
    if (period === "PM" && hours !== 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;

    return new Date(year, month, day, hours, minutes);
  };

  useEffect(() => {
    if (!data || typeof data !== 'object') return;

    
    const dataArray = Object.values(data);

    
    const filtered = dataArray.filter(item => item.client?.state === 4 || item.client?.state === 5);

    
    filtered.sort((a, b) => convertToDate(b.client?.date) - convertToDate(a.client?.date));

    
    const searchFiltered = search
      ? filtered.filter(item => item.client?.name?.toLowerCase().includes(search.toLowerCase()))
      : filtered;

    setFilteredData(searchFiltered);
    setCurrentPage(1);
  }, [data, search]);

  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  
  const formatCurrency = value => 
    new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(value || 0);


  const handleSelectOrder = (order)=>{
    dispatch(setOrderReports(order))
  }

  return (
    <>
      <hr />
      <h4 className="text-center text-apple mt-5 mb-3">Historial de ventas</h4>
      <div className="row">
        <div className="col-md-6">
          <InfoGreen text="Acá podrás ver las órdenes que fueron ENTREGADAS o CANCELADAS." />
        </div>
        <div className="col-md-6 align-self-center mb-4 mb-md-0 mt-2 mt-md-0">
          <div className="d-flex align-items-center">
            <div className="inputDash w-100 m-0">
              <input 
                type="text" 
                placeholder="Buscar por nombre"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            {search && (
              <button 
                className="btn-apple px-3 ms-3 w-auto rounded-3"
                onClick={() => setSearch('')}
              >
                Limpiar
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Encabezados de la tabla */}
      <div className="bg-b1 p-3 rounded my-3 text-apple d-none d-md-block">
        <div className="row text-center">
          <div className="col-md-2"><span>Nombre</span></div>
          <div className="col-md-2"><span>Celular</span></div>
          <div className="col-md-2"><span>OP</span></div>
          <div className="col-md-2"><span>Total</span></div>
          <div className="col-md-2"><span>Estado</span></div>
          <div className="col-md-2"><span>Domicilio/Acciones</span></div>
        </div>
      </div>

      {/* Renderizado de filas */}
      {currentItems.length > 0 ? (
        currentItems.map((item, index) => (
          <div key={index} className="row text-start text-md-center border-botton-greenDark py-1 text-white">
            <div className="col-md-2  align-self-center">
              <div className="row">
                <div className="col-6 d-block d-md-none"><strong className='text-apple'>Nombre:</strong></div>
                <div className="col-md-12 col-6">
                  <p className="m-0 text-truncate">{item.client?.name || 'Sin nombre'}</p>
                </div>
              </div>
            </div>
            <div className="col-md-2  align-self-center">
              <div className="row">
                <div className="col-6 d-block d-md-none"><strong className='text-apple'>Celular:</strong></div>
                <div className="col-md-12 col-6">
                  <p className="m-0 text-truncate">{item.client?.phone || 'No disponible'}</p>
                </div>
              </div>
            </div>
            <div className="col-md-2  align-self-center">
              <div className="row">
                <div className="col-6 d-block d-md-none"><strong className='text-apple'>OP:</strong></div>
                <div className="col-md-12 col-6">
                  <p className="m-0 text-truncate">{item.op || 'N/A'}</p>
                </div>
              </div>
            </div>
            <div className="col-md-2  align-self-center">
              <div className="row">
                <div className="col-6 d-block d-md-none"><strong className='text-apple'>Total:</strong></div>
                <div className="col-md-12 col-6">
                  <p className="m-0 text-truncate">{formatCurrency(item.payment?.total)}</p>
                </div>
              </div>
            </div>
            <div className="col-md-2  align-self-center">
              <div className="row">
                <div className="col-6 d-block d-md-none"><strong className='text-apple'>Estado:</strong></div>
                <div className="col-md-12 col-6">
                  <p className="m-0 text-truncate">
                    {
                      item.client?.state === 4 
                        ? (<span className='labelOp'>Entregado</span>) 
                        : item.client?.state === 5 ?
                          (<span className='labelReq'>Cancelado</span>)
                          : 'Desconocido'
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-2  align-self-center">
              <div className="row">
                <div className="col-12 col-md">
                  <div className="row">
                    <div className="col-6 d-block d-md-none"><strong className='text-apple'>Domicilio:</strong></div>
                    <div className="col-md-12 col-6">
                      <p className="text-center w-50 m-0">{item.payment?.delivery ? 'Sí' : 'No'}</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center text-md-start col-md my-2 my-md-0">
                  <button
                    className="btnGreen py-1 px-3 m-0"
                    type="button"
                    onClick={() => handleSelectOrder(item)}
                    data-bs-toggle="modal"
                    data-bs-target="#modalWatchOrder"
                  >
                    Ver
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center text-apple py-3">
          <p>No hay resultados disponibles</p>
        </div>
      )}

      
      {filteredData.length > itemsPerPage && (
        <div className="mt-5">
          <div className="row justify-content-center">
            {currentPage > 1 && (
              <div className="col-md-3 col-6">
                <button
                  className="btn-line-apple"
                  onClick={() => setCurrentPage(prev => prev - 1)}
                  disabled={currentPage === 1}
                >
                  Adelante
                </button>
              </div>
            )}
            {indexOfLastItem < filteredData.length && (
              <div className="col-md-3 col-6">
                <button
                  className="btn-line-apple"
                  onClick={() => setCurrentPage(prev => prev + 1)}
                  disabled={indexOfLastItem >= filteredData.length}
                >
                  Atrás
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      
      <div className="py-4" />
    </>
  );
};

export default Table;
