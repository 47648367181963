import React, { useEffect, useState } from 'react'
import cat from '../../../img/brand/space_cat.webp'
import { useSelector } from 'react-redux'
import updateOnlyOnePiece from '../../../firebase/update/updateOnlyOnePiece'

const ThankCredits = () => {

  const {paymentState, name, userID} = useSelector(s => s.log)
  const [statePayment, setStatePayment] = useState({})

  useEffect(()=>{
    if(paymentState.c === "a") setStatePayment({time: "1 año", plan: "PREMIUM"})
    if(paymentState.c === "b") setStatePayment({time: "1 año", plan: "CORPORATIVO"})
    if(paymentState.c === "c") setStatePayment({time: "6 meses", plan: "PREMIUM"})
    if(paymentState.c === "d") setStatePayment({time: "6 meses", plan: "CORPORATIVO"})
    
  },[paymentState])

  const settings = {
    a: false,
    b: false,
    c: ""
  }

  const closeModal = async () =>{
    await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAUSER, 'p', settings)
  }

  return (
    <>
    {
      paymentState.a && (

        <section className="popupCredits">
          <div className="content">
            <button
              type='button'
              className="closeModal"
              onClick={closeModal}
            />
            <div className="row">
              <div className="col-md-6 align-self-center">
                <div className="p-4">
                  <div className="d-flex align-items-center d-md-block">
                    <h5>¡Gracias {name} por actualizar {statePayment.time} tu Licencia Fooder - plan {statePayment.plan}</h5>
                  </div>
                  <p className="my-3">Ahora podrás continuar ofreciendo una experiencia única a tus clientes y alcanzar nuevos éxitos</p>
                  <div className="mt-4 mt-md-3">
                    <button 
                      type='button'
                      className="btnWhite w-100"
                      onClick={closeModal}
                    >Cerrar</button>
                  </div>
                </div>
              </div>
              <div className="col-md-6 align-self-center order-first order-md-last text-center">
                <img
                  src={cat}
                  alt=""
                  className='img-fluid cat'
                />
              </div>
            </div>
          </div>
        </section>

      )
    }
    </>
  )
}

export default ThankCredits
