import React from 'react'
import logo from '../../img/vector/logo_fooder.svg'
import InfoGreen from '../admin/dashboard/InfoGreen'
import { Link } from 'react-router-dom'

const Terms = () => {
  return (
    <>
      <header className="sticky-top bg-white shadow p-3 text-center">
        <Link to='/'><img src={logo} alt="" style={{width: '120px'}}/></Link>
      </header>
      <main className='bg-white'>
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-3 col-11 pt-3 pt-md-5 getBack">
                <div className='getBack mt-4 mt-md-0'>
                  <Link to="/" className='btnLink'>Volver</Link>
                </div>
              </div>
              <div className="col-md-7 py-5 col-11">
                <div className="scrollTYC">
                  <div className="text-center mb-5">
                    <h2 className='mb-5'>Términos y Condiciones de Uso de Fooder</h2>
                    <hr />
                  </div>
                  <h5 className='mt-5'>1. Introducción</h5>
                  <p><strong>Bienvenido a Fooder</strong>, una plataforma diseñada para que los restaurantes puedan crear su propio eCommerce, gestionar su menú digital y recibir pedidos y pagos en línea de manera eficiente.</p>
                  <p>Estos <strong>Términos y Condiciones</strong> regulan el uso de nuestra plataforma. Al registrarse y utilizar los servicios de Fooder, usted acepta estos términos en su totalidad. Si no está de acuerdo con alguno de los puntos, le recomendamos no utilizar nuestra plataforma.</p>
                  
                  <h5 className='mt-5'>2. Definiciones</h5>
                  <p>Para una mejor comprensión de los presentes términos, se definen los siguientes conceptos:</p>
                  <ul>
                    <li><strong>Fooder:</strong> Plataforma web que permite a los restaurantes gestionar su eCommerce, administrar pedidos y recibir pagos en línea.</li>
                    <li><strong>Super Administrador:</strong> Persona o equipo encargado de aprobar solicitudes de registro y crear usuarios dentro de la plataforma.</li>
                    <li><strong>Administrador:</strong> Usuario aprobado que gestiona un restaurante dentro de Fooder y tiene acceso a las funcionalidades del sistema.</li>
                    <li><strong>Cliente:</strong> Persona que accede al menú digital de un restaurante para realizar un pedido.</li>
                    <li><strong>Planes de Suscripción:</strong> Modelos de pago que permiten a los restaurantes mantener activo su menú digital y acceder a distintas funcionalidades según el plan seleccionado.</li>
                  </ul>

                  <h5 className='mt-5'>3. Registro y Aprobación</h5>
                  <p>El registro inicial en Fooder se realiza a través de la página web, pero <strong>no garantiza automáticamente la activación de una cuenta.</strong> Todas las solicitudes de registro son revisadas por el Super Administrador, quien puede aprobar o rechazar la solicitud según criterios internos de verificación.</p>
                  <p>Una vez aprobada la solicitud, el usuario se convierte en Administrador y obtiene acceso a su panel de control para gestionar su restaurante.</p>


                  <h5 className='mt-5'>4. Planes disponibles</h5>
                  <p>En Fooder, entendemos que cada restaurante tiene necesidades diferentes, por eso no ofrecemos planes rígidos. En su lugar, te brindamos la posibilidad de ajustar tu plan según la demanda de tu negocio.</p>
                  <ul>
                    <li>¿Tienes un restaurante pequeño y quieres comenzar con pocas funciones?</li>
                    <li>¿Manejas alto volumen de pedidos y necesitas mayor capacidad?</li>
                    <li>¿Quieres habilitar pagos en línea o funciones avanzadas?</li>
                  </ul>
                  <p>Hablemos y <strong>diseñemos un plan a tu medida</strong>. agenda una conversación con nosotros al 312 559 9832. Te ayudaremos a encontrar la mejor opción para tu negocio.</p>

                  <h5 className='mt-5'>5. Actualización de Planes y Cobros Proporcionales</h5>
                  <p>Si un Administrador desea actualizar su plan a uno superior antes de que finalice su periodo, podrá hacerlo pagando solo la diferencia proporcional del tiempo restante.</p>
                  <h6>Ejemplo:</h6>
                  <ul>
                    <li>Si un usuario con 6 meses pagados quiere actualizarse a 1 año, el sistema calculará la diferencia de meses restantes y descontará el valor proporcional del plan anterior.</li>
                    <li>El monto exacto será calculado automáticamente antes de confirmar el pago.</li>
                  </ul>

                  <h5 className='mt-5'>6. Pagos y Pasarela de Pago (Bold)</h5>
                  <p>Fooder permite a los restaurantes recibir pagos en línea a través de la pasarela de pagos Bold</p>
                  <h6>Aclaraciones del uso de la pasarela de pagos Bold</h6>
                  <ul>
                    <li>Los fondos recaudados por las ventas se transferirán directamente a la cuenta bancaria registrada por el Administrador en Bold.</li>
                    <li>Los pagos pueden verse reflejados hasta en 30 minutos posterior a la compra</li>
                    <li>Fooder no interviene en la gestión de los pagos fuera de la comisión establecida.</li>
                    <li>No estamos obligados a generar facturas electrónicas por los pagos recibidos desde el menú digital.</li>
                    <li>Los clientes recibirán un correo de agradecimiento por parte del administrador cuando la compra se registra efectiva.</li>
                    <li>Las sugerencias, cancelaciones del pedido, devolución de dinero se debe gestionar directamente por el administrador. Fooder es ajeno al comportamiento de las pasarelas de pago, de tal forma que la aclaración de los estados de pago, quejas, tiempo de respuesta o sugerencias del funcionamiento de la pasarela de pagos Bold no tiene relación directa por el <strong>CMS FOODER</strong></li>
                  </ul>
                  <InfoGreen
                    text='NOTA IMPORTANTE: Los restaurantes deben asegurarse de cumplir con los requisitos de Bold y proporcionar información veraz para recibir pagos sin inconvenientes.'
                  />

                  <h5 className='mt-5'>7. Uso de la Plataforma</h5>
                  <p>Fooder ofrece herramientas para gestionar un eCommerce de manera eficiente, incluyendo:</p>
                  <ul>
                    <li><strong>Menú Digital Personalizado:</strong> Página pública con la lista de productos y precios del restaurante.</li>
                    <li><strong>Pedidos en Línea:</strong> Los clientes pueden realizar órdenes a través del menú digital.</li>
                    <li><strong>Pagos Integrados:</strong> Procesamiento de pagos en línea mediante Bold.</li>
                    <li><strong>Mi Cocina:</strong> Sección para gestionar pedidos en tiempo real.</li>
                    <li><strong>Reportes y Estadísticas:</strong> Análisis de ventas, desempeño de productos y clientes frecuentes.</li>
                    <li><strong>Generación de Código QR:</strong> Para compartir el menú digital de manera rápida.</li>
                  </ul>

                  <h6>Limitaciones del servicio</h6>
                  <p><strong>Fooder no es un sistema de punto de venta (POS)</strong>, sino una herramienta de gestión de pedidos y pagos en línea.</p>
                  <p>No nos hacemos responsables por errores en la preparación o entrega de pedidos por parte del restaurante.</p>

                  <h5 className='mt-5'>8. Inactividad de Cuentas</h5>
                  <p>Si una cuenta permanece inactiva por <strong>6 meses consecutivos, Fooder se reserva el derecho de eliminarla.</strong></p>
                  <p>Se considerará inactiva una cuenta que:</p>
                  <ul>
                    <li>No haya recibido pedidos en el último semestre.</li>
                    <li>No haya ingresado al panel de administración en 6 meses.</li>
                    <li>No ha realizado la recarga de tiempo durante los 6 meses posteriores al último vencimiento.</li>
                    <li>Se comprueba que la cuenta está replicada en otro menú que perpetúa el periodo de prueba cíclicamente.</li>
                  </ul>


                  <h5 className='mt-5'>9. Responsabilidades del Administrador</h5>
                  <p>El Administrador es responsable de:</p>
                  <ul>
                    <li>Mantener actualizada la información de su menú digital, incluyendo productos, precios y disponibilidad.</li>
                    <li>Gestionar los pedidos recibidos y cumplir con los tiempos de entrega establecidos con sus clientes.</li>
                    <li>Asegurar que los pagos procesados a través de Bold sean correctamente gestionados.</li>
                    <li>Evitar el uso indebido de la plataforma para actividades fraudulentas o ilegales.</li>
                    <li>Generar sus propios términos y condiciones para medir el alcance de los beneficios y obligaciones de la actividad económica que ejerce.</li>
                  </ul>

                  <h5 className='mt-5'>10. Propiedad Intelectual</h5>
                  <p>Todos los derechos de propiedad intelectual de Fooder, incluyendo su diseño, código fuente, imágenes y contenido, <strong>son propiedad exclusiva de Fooder.</strong></p>
                  <InfoGreen
                    text='Queda prohibida la copia, distribución, modificación o uso de cualquier material sin previa autorización escrita.'
                  />

                  <h5 className='mt-5'>11. Uso Inadecuado de la Plataforma</h5>
                  <p>Fooder se reserva el derecho de suspender o cancelar cuentas que incurran en:</p>
                  <ul>
                    <li>Publicación de información falsa o engañosa.</li>
                    <li>Uso fraudulento de la plataforma.</li>
                    <li>Intento de manipulación de pagos o pedidos.</li>
                  </ul>
                  <small>En caso de infracción grave, la cuenta puede ser cancelada sin previo aviso y sin reembolso.</small>

                  <h5 className='mt-5'>12. Limitación de Responsabilidad</h5>
                  <p>Fooder no es responsable por:</p>
                  <ul>
                    <li>La calidad de los productos vendidos por los restaurantes.</li>
                    <li>Errores en la entrega de pedidos.</li>
                    <li>Pérdidas económicas derivadas del uso de la plataforma.</li>
                  </ul>
                  <p>Nos comprometemos a garantizar el correcto funcionamiento del sistema, pero <strong>no podemos garantizar disponibilidad ininterrumpida</strong> debido a factores técnicos externos.</p>


                  <h5 className='mt-5'>13. Cancelación del Servicio y Reembolsos</h5>
                  <ul>
                    <li>El Administrador puede cancelar su suscripción en cualquier momento, pero no se realizarán reembolsos por el tiempo no utilizado.</li>
                    <li>Fooder puede cancelar una cuenta en caso de violación de estos términos, sin derecho a reembolso.</li>
                  </ul>



                  <h5 className='mt-5'>14. Modificaciones a los Términos y Condiciones</h5>
                  <p>Fooder se reserva el derecho de modificar estos términos en cualquier momento. Se notificará a los usuarios sobre cambios importantes y el uso continuado de la plataforma <strong>implicará su aceptación.</strong></p>

                  <h5 className='mt-5'>15. Contacto</h5>
                  <p>Para consultas o soporte, puede escribirnos a: soporte@fooder.com</p>

                  <h5 className='mt-5'>Aceptación de los Términos</h5>
                  <p>Al utilizar Fooder, usted confirma que ha leído y acepta estos Términos y Condiciones.</p>
                  <div className="text-center my-4 pt-5">
                    <p><strong>Última actualización:</strong> 14 de enero de 2025</p>
                    <small>Al continuar utilizando <strong>Fooder</strong>, usted acepta haber leído y entendido nuestras políticas de tratamiento de datos personales.</small>
                  </div>

                </div>

               


              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Terms