import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../../../img/dashboard/fooder_dashboard.svg'
import { useForm } from 'react-hook-form'
import { setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions'
import { auth, db } from '../../../../firebase/credentials'
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import { doc, updateDoc } from "firebase/firestore"
import dismissStep from './fx/dismissStep'



const ChangePassword = () => {

  const {step, userID} = useSelector(s => s.log)
  const [hidePass, setHidePass] = useState(true)
  const [hidePass2, setHidePass2] = useState(true)
  const [criteria, setCriteria] = useState({
    length: false,
    specialChar: false,
    uppercase: false,
    lowercase: false,
  })

  const dispatch = useDispatch()

  const {register, handleSubmit, formState: { errors}, watch} = useForm({
    mode: 'onChange'
  })

  const newPass = watch("newPass", "")



  const submit = (data) => {
    const user = auth.currentUser
    dispatch(setStateLoading(true))
    if (user) {
      // Pedir al usuario que ingrese su contraseña actual
      const currentPassword = 'nuevousuario123'
  
      if (currentPassword) {
        const credential = EmailAuthProvider.credential(user.email, currentPassword)
  
        reauthenticateWithCredential(user, credential)
          .then(async() => {
            const ref = doc(db, process.env.REACT_APP_DATAUSER, userID)
            await updateDoc(ref, {d: 1})
            return updatePassword(user, data.newPass)
          })
          .then(() => {
            dispatch(setTextNotificate('Contraseña actualizada'))
            dispatch(setStateNotification(true))
            dispatch(setIsError(false))
          })
          .catch(() => {
            dispatch(setTextNotificate('No pudo ser actualizada tu contraseña'))
            dispatch(setStateNotification(true))
            dispatch(setIsError(true))
          })
      } else {
        dispatch(setTextNotificate('Reautenticación cancelada'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true))
      }
    } else {
      console.error("No hay usuario autenticado.")
      dispatch(setTextNotificate('No hay usuario autenticado'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true))
    }
    dispatch(setStateLoading(false))
  }


  useEffect(() => {
    const length = newPass.length >= 8
    const specialChar = /[!@#\/"\.\$!\*]/.test(newPass) // Incluir *
    const uppercase = /[A-Z]/.test(newPass)
    const lowercase = /[a-z]/.test(newPass)
  
    setCriteria({
      length,
      specialChar,
      uppercase,
      lowercase,
    })
  }, [newPass])
  


  const handleDismiss = async()=>{
    const response = await dismissStep(userID, 1)
    if(!response){
      dispatch(setTextNotificate('No es posible ir al siguiete paso, es necesario escribir tu nueva contraseña'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true))
    }
  }

  return (
    <>
    {
      step === 0 && (
        <section className="changePassword">
          <div className="content">
            <img src={logo} alt="" className='logo img-fluid'/>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h4 className='text-green'>¡Bienvenid@ al CMS Fooder!</h4>
                <p>Para comenzar, actualiza tu contraseña</p>
                <hr />
              </div>
  
              <div className="col-md-12">
                <form onSubmit={handleSubmit(submit)}>
                  <div className="row justify-content-center mt-3">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-12">
                          <label>Nueva contraseña</label>
                          <div className="inputPass">
                            <input
                              autoComplete="off"
                              type={hidePass ? 'password': 'text'}
                              {...register('newPass', {
                                required: {value: true, message: 'Campo requerido'},
                                minLength: {value: 8 , message: 'La contraseña debe ser mayor de 8 caracteres'},
                                maxLength: {value: 40 , message: 'Contraseña demasiado extensa'},
                                pattern: {
                                  value: /^(?=.*[!@#\/"\.\$!\*])(?=.*[A-Z])(?=.*[a-z]).{8,}$/,
                                  message: 'La contraseña debe cumplir con los requisitos especificados',
                                }                                
                                
                              })}
                            />
                            <button
                              type='button'
                              className={hidePass ? 'hide': ''}
                              onClick={()=> setHidePass(!hidePass)}
                            />
                          </div>
                          {errors.newPass && <small className='errorDash'>{errors.newPass.message}</small>}
                        </div>
                        <div className="col-12">
                          <label>Confirmar contraseña</label>
                          <div className="inputPass">
                            <input
                              autoComplete="off"
                              type={hidePass2 ? 'password': 'text'}
                              {...register('passConfirm', {
                                required: {value: true, message: 'Campo requerido'},
                                validate: value => value === newPass || 'Las contraseñas no coinciden',
                              })}
                            />
                            <button
                              type='button'
                              className={hidePass2 ? 'hide': ''}
                              onClick={()=> setHidePass2(!hidePass2)}
                            />
                          </div>
                          {errors.passConfirm && <small className='errorDash'>{errors.passConfirm.message}</small>}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 align-self-center">
                      <div className="row">
                        <div className="col-12">
                          <p className='text-start text-apple'>Por motivos de seguridad debes cumplir los siguientes parámetros:</p>
                          <div className="listStrongPassword">
                            <div className="row">
                              <span className={criteria.length ? 'active' : ''}>
                                Al menos 8 caracteres
                              </span>
                              <span className={criteria.specialChar ? 'active' : ''}>
                                Al menos un carácter especial (@, #, /, ", ., $, !)
                              </span>
                              <span className={criteria.uppercase ? 'active' : ''}>
                                Al menos una letra mayúscula
                              </span>
                              <span className={criteria.lowercase ? 'active' : ''}>
                                Al menos una letra minúscula
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <div className="row justify-content-center">
                        <div className="col-md-4">
                          <button
                            type='submit'
                            className='btnGreen w-100'
                          >Actualizar contraseña</button>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-md-4">
                          <div className="text-center mt-3">
                            <button
                              type='button'
                              className='btn-line-white'
                              onClick={handleDismiss}
                            >Saltar paso</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )
    }
    </>
  )
}

export default ChangePassword