import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Expiration from './Expiration'
import CardsNovelties from './CardsNovelties'
import postImg from '../../../../img/dashboard/btn_post_download.svg'
import stateImg from '../../../../img/dashboard/btn_state_download.svg'
import { resetAllPost } from '../../../../redux/reducer/post'
import Slider from 'react-slick'
import plate1 from '../../../../img/dashboard/photo/plate_1.webp'
import plate2 from '../../../../img/dashboard/photo/plate_2.webp'
import plate3 from '../../../../img/dashboard/photo/plate_3.webp'
import plate4 from '../../../../img/dashboard/photo/plate_4.webp'
import letters from '../../../../img/vector/text_circle.svg'
import banner from '../../../../img/dashboard/photo/banner.webp'
import bannerSm from '../../../../img/dashboard/photo/banner_sm.webp'
import useNotificationPermission from '../../../../hooks/useNotificationPermission'
import iconNotification from '../../../../img/vector/icon_notification.svg'




const Novelties = () => {
  

  const {name, credits} = useSelector(state => state.log)
  const dispatch = useDispatch()
  const { permission } = useNotificationPermission()
  
  

  const currentYear = new Date().getFullYear()
  

  const sendToPost = ()=>{
    dispatch(resetAllPost())
    document.querySelector('#post-tab').click()
  }
  
  const sendToState = ()=>{
    dispatch(resetAllPost())
    document.querySelector('#state-tab').click()

  }

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    className: "slidesN",
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4200,
    fade: true,
    waitForAnimate: false
  }


  useEffect(() => {
    setTimeout(() => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      tooltipTriggerList.forEach(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    }, 500);
  }, []);

  const handleBold = ()=>{
    let btn = document.getElementById('payments-tab')
    if(btn) btn.click()
  }

  return (
    <>
      <section className='p-3 sectionNovelties'>
        <button
          onClick={handleBold}
        >
          <img src={banner} alt="" className='img-fluid rounded-4 mb-2 d-none d-md-block'/>
          <img src={bannerSm} alt="" className='img-fluid rounded-4 mb-2 d-block d-md-none'/>
        </button>

        

        <div className="bg-b1 rounded-lg">
          <div className="row">
            <div className="col-xs-6 col-lg-5 align-self-end">
              <div className="sliderNovelties">
                <Slider {...settings}>
                  <img src={plate1} alt="slide" className='img-fluid'/>
                  <img src={plate2} alt="slide" className='img-fluid'/>
                  <img src={plate3} alt="slide" className='img-fluid'/>
                  <img src={plate4} alt="slide" className='img-fluid'/>
                </Slider>
                <img src={letters} alt="" className='img-fluid letters'/>
              </div>
            </div>
            <div className="col-xs-6 col-lg-7 align-self-center order-xs-first order-md-last px-4 pb-3 pt-0 pt-md-3">
              <div className="row">
                <div className="col-md-6 align-self-center">
                  <h3 className="text-apple mb-0 mt-4 mt-md-0">Digitalizando <br/> comidas</h3>
                </div>
                <div className="col-md-6 order-first order-md-last">
                  <div className={`bg-b2 p-2 rounded text-center ${credits >=1 ? '' : 'inactiveCredits'}`}>
                    {
                      credits >=1 ? 
                      <div>
                        <h4 className="licenceDays">{credits} días</h4>
                        <div className="d-flex justify-content-center align-items-center py-1">
                          <div className="text-center text-white"><small>de Licencia Fooder</small></div>
                          <button
                            className='iconInfo ms-2'
                            type='button'
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="El botón para recargar tu licencia se habilitará en los últimos 30 días de tu plan."
                          >
                            i
                          </button>
                        </div>
                      </div>

                      :
                      <span className="d-block">No pierdas la oportunidad de prestar un mejor servicio a tus clientes</span>
                    }
                    {
                      credits <= 30 && (
                        <button
                          className="addTime w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#paymentModalDash"
                        >Actualizar licencia</button>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-11 mt-2 mt-md-4">
                {
                  credits >=1 ? 
                  <p>Hola <span className='credits-text text-capitalize'>{name || 'usuario'}</span> ¿que tal?, nuestra plataforma te ofrece una solución innovadora y práctica para actualizar tus menús de manera constante. Con tan solo unos clics, podrás mantener tus ofertas actualizadas y sorprender a tus clientes con una experiencia moderna y atractiva.</p>
                  :
                  <p>Hola <span className='credits-text text-capitalize'>{name || 'usuario'}</span>. Es una pena que se hayan agotado tu licencia Fooder, la buena noticia es que estás a tiempo de restablecer todos nuestros servicios <span className='text-apple'>AGREGANDO TIEMPO</span> a tu menú digital. Trabamos constantemente por una excelente experiencia en el proceso.</p>
                }
              </div>
            </div>
          </div>
        </div>

        {
          (permission === 'default' || permission === 'granted') ? (
            <div className="alertAqua mt-3 mb-0">
              <img src={iconNotification} alt="icon" className='icon me-3 mb-3 mb-md-0'/>
              <p className="m-0">Tienes activadas las notificaciones y permisos en este navegador, <strong>si aún no puedes ver las notificaciones de tus pagos Bold, </strong> revisa la configuración global de notificaciones de tu dispositivo. Recomendamos que uses <strong>Chrome</strong> para acceder más fácil a las notificaciones.</p>
            </div>
          ):
          (
            <div className="alertAqua danger mt-3 mb-0">
              <img src={iconNotification} alt="icon" className='icon me-3 mb-3 mb-md-0'/>
              <p className="m-0"><strong>No tienes activas las notificaciones.</strong> Otorga permisos del navegador a esta página, no olvides revisar las notificaciones globales de tu dispositivo para este navegador. <strong>¡Actívalos para que te avisen cuando llega un pedido desde Bold!</strong></p>
            </div>
          )
        }

        {
          credits >= 1 ? 
          (
            <div className="row justify-content-center mb-4">
              <div className="col-md-12">
                <CardsNovelties />
              </div>
              <div className="col-md-12 my-4">
                <div className="row">
                  <div className="col-md-6 align-self-center">
                    <h5 className=' my-3 text-apple'>Descarga de imágenes ilimitada</h5>
                    <p>Promociona tus productos en las redes sociales, ofrecemos dos tamaños de imagen que se adecuan a tus necesidades. <strong className='text-white'>¡Descúbrelos!</strong></p>
                  </div>
                  <div className="col-md-6 align-self-center">
                    <div className="postSectionNovelties">
                      <button type='button' onClick={sendToPost}>
                        <img src={postImg} alt="postimg" className='img-fluid'/>
                        <span>Post</span>
                      </button>
                      <button type='button' onClick={sendToState}>
                        <img src={stateImg} alt="postimg" className='img-fluid'/>
                        <span>Estados</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>



              <div className="col-12 mt-3 text-center mb-5">
                <small>Copyright FOODER {currentYear} | Digitaliza tus comidas | - Todos los derechos reservados</small>
                <div>
                  <small><strong className="text-green">Versión 1.2</strong></small>
                </div>
              </div>
            </div>
          )
          :
          <Expiration/>
        }


      </section>
    </>
  )
}

export default Novelties


