import React from "react";
import InfoGreen from "../InfoGreen";

const Expiration = () => {
  

  return (
    <>
      <div className="mt-4">
        <div className="row justify-content-center">
          <div className="col-md-5 px-5 mt-4 mt-md-0">
          </div>
          <div className="col-md-12 align-self-center order-first order-md-last">
            <p className="text-apple text-center mb-4 mt-4 mt-md-0">
              Tienes
              <span className="dangerLabel mx-1">0</span> días de licencia Fooder
            </p>
            <InfoGreen
              bg={true}
              text='De acuerdo a nuestras políticas de tratamiento de datos e información de nuestros clientes alojada en esta plataforma, almacenamos toda la información de tu menú por un periodo máximo de 6 meses a partir de la fecha de suspensión, luego de ese tiempo perderás toda la información almacenada con nosotros.'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Expiration;
