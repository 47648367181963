import React, { useState } from 'react'
import leaf from '../../img/brand/hoja1.webp'
import footer from '../../img/brand/login_footer.webp'
import { useForm } from 'react-hook-form'
import handleLogin from './fx/login'
import { sendPasswordResetEmail } from "firebase/auth"
import { auth } from '../../firebase/credentials'
import { useDispatch } from 'react-redux'
import { setLoadLanding } from '../../redux/reducer/interactions'


const ModalLogin = () => {

  const [isLogin, setIsLogin] = useState(true)
  const [hidePass, setHidePass] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isSend, setIsSend] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const dispatch = useDispatch()

  const {register, handleSubmit, formState:{errors}, reset} = useForm({ mode: 'onChange'})

  const resetForm = ()=>{
    setIsLogin(true)
    setHidePass(true)
    setIsError(false)
    reset()
  }

  const returnToLogin = ()=>{
    resetForm()
    setIsSend(false)
    setShowConfirm(false)
  }

  


  const submit = async (data) => {
    

    dispatch(setLoadLanding(true));

    if (isLogin) {
      const { email, pass } = data;
      const { response, error } = await handleLogin(email, pass);
      if (!response) {
        console.log(error);
        setIsError(true);
        reset();
      } else {
        document.getElementById("closeLoginModal").click();
      }
    } else {
      sendPasswordResetEmail(auth, data.emailrecover)
        .then(() => {
          setShowConfirm(true);
          setIsSend(true);
        })
        .catch((error) => {
          console.log(error);
          setShowConfirm(false);
          setIsSend(false);
        });
    }

    dispatch(setLoadLanding(false));
  };

  

  return (
    <div className="modal modalLogin fade" id="modalLoginL" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalLoginLLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-relative">
            <div className="d-flex justify-content-end">
              <button
                className='closeBtn'
                type='button'
                data-bs-dismiss="modal"
                onClick={resetForm}
                id='closeLoginModal'
              />
            </div>
            <img src={leaf} alt="" className='img-fluid pa leaf'/>
            <div className="row justify-content-center">
              <div className="col-md-8 col-9">
                {
                  showConfirm ? (
                    <>
                      <div className="text-center py-4">
                        <div className="row justify-content-center">
                        </div>
                        <h2>¡Correo enviado con éxito!</h2>
                        <p>No olvides revisar tu bandeja de entrada o los filtros de spam.</p>
                        <div className="row justify-content-center">
                          <div className="col-md-10">
                            <button
                              type='button'
                              className='btnGreen'
                              onClick={returnToLogin}
                            >Iniciar sesión</button>
                          </div>
                        </div>

                      </div>
                    </>
                  ):
                  (
                    <>
                      <h4 className='my-4'>{isLogin ? 'Iniciar sesión' : 'Recuperar contraseña'}</h4>
                      {isLogin === false && (
                        <p>Escribe el email registrado a tu cuenta <strong>FOODER</strong>, recibirás un correo donde podrás actualizar tu contraseña.</p>
                      )}
                      {
                        isSend && (
                          <div className="errorAuth text-center">
                            ¡Ha ocurrido un problema!. No se detectó un correo válido para restablecer la contraseña
                          </div>
                        )
                      }
                      <form onSubmit={handleSubmit(submit)}>
                        <div className="row justify-content-center">
                          <div className="col-md-12">
                          {
                            isError && (
                              <div className="errorAuth">
                                El email o contraseña no coinciden con algún usuario existente.
                                <button type='button' onClick={()=> setIsError(false)}>CERRAR</button>
                              </div>
                            )
                          }
                          {
                            isLogin ? (
                              <>
                                <div className="col-12">
                                  <input
                                    type="mail"
                                    autoComplete="off"
                                    className='inputLanding'
                                    placeholder='Email'
                                    {...register('email', {
                                      required: {value: true, message: 'Campo requerido'},
                                      pattern: {value: /^[a-zA-Z][a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/ , message: 'Escribe un formato de email válido'}
                                    })}
                                  />
                                  {errors.email && <small className='errorDash'>{errors.email.message}</small>}
                                </div>
                                <div className="col-12">
                                  <div className="inputPass">
                                    <input
                                      autoComplete="off"
                                      type={hidePass ? 'password': 'text'}
                                      placeholder='Contraseña'
                                      {...register('pass', {
                                        required: {value: true, message: 'Campo requerido'},
                                        minLength: {value: 6 , message: 'La contraseña debe ser mayor de 6 caracteres'},
                                        maxLength: {value: 30 , message: 'Contraseña demasiado extensa'},
                                      })}
                                    />
                                    <button
                                      type='button'
                                      className={hidePass ? 'hide': ''}
                                      onClick={()=> setHidePass(!hidePass)}
                                    />
                                  </div>
                                  {errors.pass && <small className='errorDash'>{errors.pass.message}</small>}
                                </div>
                              </>
                            ):
                            (
                              <>
                                <div className="col-12">
                                  <input
                                    type="mail"
                                    className='inputLanding'
                                    autoComplete="off"
                                    placeholder='Email'
                                    {...register('emailrecover', {
                                      required: {value: true, message: 'Campo requerido'},
                                      pattern: {value: /^[a-zA-Z][a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/ , message: 'Escribe un formato de email válido'}
                                    })}
                                  />
                                  {errors.emailrecover && <small className='errorDash'>{errors.emailrecover.message}</small>}
                                </div>
                              </>
                            )
                          }
                        </div>
                        
                        <div className="col-md-12 col-12">
                          {isLogin ? (
                            <button
                              type='submit'
                              className='btnRegister w-100'
                            >INGRESAR</button>
                          ):
                            (
                              <button
                                type='submit'
                                className='btnRegister w-100'
                              >ENVIAR CORREO</button>
                            )
                          }
                        </div>
                        </div>
                      </form>
                      <div className="mt-5 mb-3 text-center">
                        <button
                          type='button'
                          className="textLink"
                          onClick={()=> setIsLogin(!isLogin)}
                        >{isLogin ? 'Olvidé mi contraseña': 'Iniciar Sesión'}</button>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
            <img src={footer} alt="" className='img-fluid px-5' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalLogin